import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/rootReducer'
import vtoCatalogServices from '../../services/vtoCatalogServices'
import { QRCodeSlice } from '../../types/qrcode'
import { VMDataExperience } from '../../types/vmdata'
import { VMListData } from '../../types/vmlistingdata'

const initialState: QRCodeSlice = {
  hash: '',
  isLoading: false,
  urlShort: {
    key: '',
    baseUrl: '',
    isLoading: false,
  },
}

export const createHash = createAsyncThunk(
  'vm-bo/qrCode/createHash',
  (action: VMDataExperience, thunkAPI) => {
    const { getState } = thunkAPI
    const state = getState() as RootState
    const uuid = state.listing.edittableUuid
    const hash = vtoCatalogServices.createHash(action, uuid)
    return hash
  }
)

export const createShortUrl = createAsyncThunk(
  'vm-bo/qrCode/createShortUrl',
  (url: string, thunkAPI) => {
    const { getState } = thunkAPI
    const state = getState() as RootState
    const currentToken = state.qrCode.hash || state.listing.edittableUuid
    if (!currentToken) {
      throw new Error('Token not found')
    }

    let key
    if (currentToken) {
      // check whether a url short has been already registered
      const vmlistdata = state.listing.listingData?.find(
        (el: VMListData) => el.token === currentToken
      )
      key = (vmlistdata && vmlistdata.shortUrl?.key) || undefined
    }
    const shortUrl = vtoCatalogServices.setShortUrl(currentToken, url, key)
    return shortUrl
  }
)

const qrCode = createSlice({
  name: 'vm-bo/qrCode',
  initialState,
  reducers: {},
  extraReducers: {
    [createHash.fulfilled.toString()]: (state, { payload }) => {
      state.hash = payload.uuid
      state.isLoading = false
    },
    [createHash.pending.toString()]: (state) => {
      state.isLoading = true
    },

    [createShortUrl.fulfilled.toString()]: (state, { payload }) => {
      state.urlShort.baseUrl = payload.baseUrl
      state.urlShort.key = payload.key
      state.urlShort.isLoading = false
    },
    [createShortUrl.pending.toString()]: (state) => {
      state.urlShort.isLoading = true
    },
  },
})

// export const {} = qrCode.actions
export default qrCode.reducer

import React from 'react'
import { Dialog, Box } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { QRCodeGenerated } from './QRCodeGenerated'
import { VMListData } from '../types/vmlistingdata'
import { getVmUrl } from '../libs/utils'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: '3rem',
      position: 'relative',
      minWidth: '50vw',
    },
  })
)

interface PreviewModalProps {
  isOpen: boolean
  onClose: () => void
  vmDataPreview: VMListData | null
}

export const PreviewModal: React.FC<PreviewModalProps> = ({
  isOpen,
  onClose,
  vmDataPreview,
}) => {
  const styles = useStyles()
  const vmUrl = vmDataPreview && getVmUrl(vmDataPreview.token)
  const { shortUrl } = vmDataPreview || {}
  const qrCodeUrl =
    shortUrl && shortUrl.key && [shortUrl.baseUrl, shortUrl.key].join('/')

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" disableEnforceFocus>
      <Box className={styles.content}>
        <QRCodeGenerated
          handleConfirm={onClose}
          qrCodeUrl={qrCodeUrl || vmUrl || undefined}
          vmUrl={vmUrl || undefined}
          title={vmDataPreview?.name}
        />
      </Box>
    </Dialog>
  )
}

import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import {
  Box,
  Modal,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import QRCode from 'qrcode.react'
import { colorPalette } from '../styleVariables'
import { useSelector, useDispatch } from 'react-redux'
import { createHash } from '../features/qrCode/qrCodeSlice'
import { selectIsLoadingHash, selectVmUrl } from '../features/qrCodeSelectors'
import { selectVmDataPreview } from '../features/vmdataSelectors'

type QRCodeModalProps = {
  isOpen: boolean
  onClose: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      backgroundColor: 'white',
      alignSelf: 'center',
      borderRadius: 5,
    },
    btnClose: {
      backgroundColor: colorPalette.blue,
      color: colorPalette.white,
      fontSize: '1rem',
      marginTop: '2rem',
      fontWeight: 400,
    },
    btnText: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    loaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 300,
      height: 300,
    },
  })
)

export const QRCodeModal: React.FC<QRCodeModalProps> = (props) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const isLoadingHash = useSelector(selectIsLoadingHash)
  const vmUrl = useSelector(selectVmUrl)
  const vmDataPreview = useSelector(selectVmDataPreview)
  const { isOpen, onClose } = props

  useEffect(() => {
    if (isOpen) {
      dispatch(createHash(vmDataPreview))
    }
  }, [dispatch, isOpen, vmDataPreview])

  return (
    <Modal open={isOpen} className={styles.modal} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        p="3rem"
        className={styles.container}
      >
        {isLoadingHash ? (
          <Box className={styles.loaderWrapper}>
            <CircularProgress />
          </Box>
        ) : (
          <QRCode size={300} value={vmUrl ?? ''} data-url={vmUrl} />
        )}
        <Button
          variant="contained"
          onClick={onClose}
          className={styles.btnClose}
        >
          <Typography className={styles.btnText}>Close</Typography>
        </Button>
      </Box>
    </Modal>
  )
}

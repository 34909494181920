import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  CircularProgress,
} from '@material-ui/core'
import { TitleEdit } from './core/TitleEdit'
import { useDispatch } from 'react-redux'
import { getListingData } from '../features/listing/listingSlice'
import VTOCatalogServices from '../services/vtoCatalogServices'
import { useAsync } from '../hooks/useAsync'
import { useHistory } from 'react-router-dom'
import { Add, ArrowBackIos, Search } from '@material-ui/icons'
import { colorPalette } from '../styleVariables'
import { ListingGroupsTable } from './ListingGroupTable'
import GroupServices from '../services/groupServices'
import { Users } from './UserList'
import { AppRoutes } from '../app/routers'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: 'left',
    },
    searchWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: '3rem',
    },
    searchInputTop: {
      marginRight: '2rem',
      minWidth: '30rem',
    },
    tableSearchWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexDirection: 'row',
      marginTop: '10rem',
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1.2rem',
    },
    searchInput: {
      marginRight: '-2rem',
      minWidth: '20rem',
      marginBottom: '1.75rem',
    },
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 100,
    },
    btnCreate: {
      height: '3rem',
      backgroundColor: colorPalette.blue,
      borderRadius: '20px',
    },
    btnText: {
      fontSize: '1.125rem',
      marginLeft: '1rem',
      fontWeight: 500,
    },
    btn: {
      fontSize: '1.125rem',
      color: colorPalette.white,
      '&:hover': {
        backgroundColor: 'rgba(213, 213, 213, 0.5)',
      },
    },
    searchIconWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    btnHome: {
      color: colorPalette.greyLight,
      fontSize: '1rem',
      fontWeight: 400,
      opacity: 0,
    },
  })
)

export interface Group {
  id: string
  createdById?: string
  createdBy?: Users
  name: string
}

export const ListingGroup: React.FC = () => {
  const styles = useStyles()
  const [createStatus, setCreateStatus] = useState(false)
  const [editableStatus, setEditableStatus] = useState(false)
  const [groups, setGroups] = useState<Group[]>()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const history = useHistory()

  const { error, setError, loading } = useAsync(
    VTOCatalogServices.getVMDataByUUID
  )
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchValue(value)
  }

  useEffect(() => {
    const onUnauthorized = () => {
      history.push('/logout')
    }

    dispatch(getListingData(onUnauthorized))
  }, [dispatch, history])

  const refetchGroup = async () => {
    const groups = await GroupServices.getAllGroups()
    setGroups(groups)
  }

  useEffect(() => {
    refetchGroup()
  }, [])

  return (
    <>
      <Box>
        <Button className={styles.btnHome}>
          <ArrowBackIos />
          <Typography>back to group list</Typography>
        </Button>
        <Box className={styles.tableSearchWrapper}>
          <TitleEdit title="Group List" className={styles.title} />
          <Box className={styles.searchContainer}>
            <Box className={styles.searchIconWrapper}>
              <TextField
                className={styles.searchInput}
                value={searchValue}
                label="Search"
                onChange={handleSearch}
              />
              <Search />
            </Box>
            <Button
              variant="contained"
              className={`${styles.btn} ${styles.btnCreate}`}
              onClick={() => setCreateStatus(true)}
            >
              <Add fontSize="large" />
              <Typography className={styles.btnText}>add new group</Typography>
            </Button>
          </Box>
        </Box>
        <ListingGroupsTable
          createStatus={createStatus}
          setCreateStatus={setCreateStatus}
          editableStatus={editableStatus}
          setEditableStatus={setEditableStatus}
          groups={groups}
          refetchGroup={refetchGroup}
          searchValue={searchValue}
        />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        message="Error: VM Data not found by hash"
        autoHideDuration={6000}
        onClose={() => setError(false)}
      />
      {loading && (
        <Box className={styles.loaderWrapper}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

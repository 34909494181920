import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Container, Box, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { colorPalette } from '../styleVariables'
import { Logo } from '../components/Logo'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../features/user/userSlice'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: 'none',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage:
        'radial-gradient(farthest-corner at 50% 100% , #1C7CE0 0%, #150051 100%)',
      color: colorPalette.white,
      animation: 'filter-animation 6s infinite ease-in',
    },
    btn: {
      padding: '1rem 5.8rem',
      fontSize: '1.125rem',
      color: colorPalette.white,
      '&:hover': {
        backgroundColor: 'rgba(213, 213, 213, 0.5)',
      },
    },
    btnList: {
      backgroundColor: 'transparent',
      border: `2px solid ${colorPalette.white}`,
      marginBottom: '1rem',
    },
    btnCreate: {
      backgroundColor: colorPalette.blue,
    },
    btnText: {
      fontSize: '1.125rem',
      marginLeft: '1rem',
      fontWeight: 500,
    },
    logo: {
      width: '16.75rem',
    },
  })
)

export const LogoutPage: React.FC = () => {
  const styles = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoadingLogin = useSelector((state) => state.user.isLoading)
  const hasError = useSelector((state) => state.user.hasError)
  const vmat = localStorage.getItem('userToken')

  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false)

  useEffect(() => {
    dispatch(userLogout())
  }, [dispatch])

  useEffect(() => {
    if (!isLoadingLogin && !hasError && !vmat) {
      history.push('/')
    }
  }, [history, isLoadingLogin, vmat, hasError])

  useEffect(() => {
    setIsErrorVisible(hasError)
  }, [hasError])

  return (
    <Container className={styles.container} disableGutters>
      <Box m="5rem" className={styles.logo}>
        <Logo />
      </Box>
      <Box display="flex" flexDirection="column"></Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isErrorVisible}
        autoHideDuration={6000}
        onClose={() => setIsErrorVisible(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          An error occured during logout.
        </MuiAlert>
      </Snackbar>
    </Container>
  )
}

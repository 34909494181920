import * as React from 'react'

import { createStyles, makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import { colorPalette } from '../../styleVariables'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      backgroundColor: colorPalette.white,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
  })
)

export const PageComponent: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const styles = useStyles()

  return (
    <Box p="2rem" className={`${styles.content} ${className || ''}`}>
      {children}
    </Box>
  )
}

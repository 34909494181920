import React from 'react'
import { BaseComponent } from '../components/core/BaseComponent'
import { PageComponent } from '../components/core/PageComponent'
import { Aside } from '../components/core/Aside'
import { Listing } from '../components/Listing'

export const LoadPage: React.FC = () => {
  return (
    <BaseComponent>
      <Aside />
      <PageComponent>
        <Listing />
      </PageComponent>
    </BaseComponent>
  )
}

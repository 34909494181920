import { Add, Search } from '@material-ui/icons'
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useHistory, useParams } from 'react-router-dom'

import { AppRoutes } from '../app/routers'
import { ArrowBackIos } from '@material-ui/icons'
import { Group } from './ListingGroups'
import GroupServices from '../services/groupServices'
import { TitleEdit } from './core/TitleEdit'
import UserServices from '../services/userServices'
import { UserTable } from './UserTable'
import VTOCatalogServices from '../services/vtoCatalogServices'
import { colorPalette } from '../styleVariables'
import { useAsync } from '../hooks/useAsync'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: 'left',
    },
    searchWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: '3rem',
    },
    searchInputTop: {
      marginRight: '2rem',
      minWidth: '30rem',
    },
    tableSearchWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexDirection: 'row',
      marginTop: '10rem',
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1.2rem',
    },
    snackBar: {
      position: 'fixed',
      top: '2rem',
      left: '2rem',
    },
    searchInput: {
      marginRight: '-2rem',
      minWidth: '20rem',
      marginBottom: '1.75rem',
    },
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 100,
    },
    btnCreate: {
      height: '3rem',
      backgroundColor: colorPalette.blue,
      borderRadius: '20px',
    },
    btnText: {
      fontSize: '1.125rem',
      marginLeft: '1rem',
      fontWeight: 500,
    },
    btn: {
      fontSize: '1.125rem',
      color: colorPalette.white,
      '&:hover': {
        backgroundColor: 'rgba(213, 213, 213, 0.5)',
      },
    },
    searchIconWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    btnHome: {
      color: colorPalette.greyLight,
      fontSize: '1rem',
      fontWeight: 400,
    },
  })
)

export interface Users {
  id: string
  username: string
  surname: string
  name: string
  email: string
  role: string
  createdAt?: string
  groupId?: string | null
  createdBy?: Users
  createdById?: string
  group?: Group
}

export const UserList: React.FC = () => {
  const styles = useStyles()
  const [createStatus, setCreateStatus] = useState(false)
  const [editableStatus, setEditableStatus] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const params = useParams<{ id: string }>()
  const [users, setUsers] = useState<Users[]>()
  const [name, setName] = useState<string>('')
  const history = useHistory()

  const { error, setError, loading } = useAsync(
    VTOCatalogServices.getVMDataByUUID
  )
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchValue(value)
  }

  const refetchUsers = useCallback(async () => {
    if (params.id) {
      const group = await GroupServices.getGroupById(params.id)
      setUsers(group.users)
      setName(group.name)
    } else {
      setName('Users List')
      const usersRes = await UserServices.getAllUsers()
      setUsers(usersRes)
    }
  }, [params.id])

  useEffect(() => {
    refetchUsers()
  }, [refetchUsers])

  return (
    <>
      <Box>
        <Button
          className={styles.btnHome}
          onClick={() => history.push(`${AppRoutes.groupList()}`)}
        >
          <ArrowBackIos />
          <Typography>back to group list</Typography>
        </Button>
        <Box className={styles.tableSearchWrapper}>
          <TitleEdit title={name} className={styles.title} />
          <Box className={styles.searchContainer}>
            <Box className={styles.searchIconWrapper}>
              <TextField
                className={styles.searchInput}
                value={searchValue}
                label="Search"
                onChange={handleSearch}
              />
              <Search />
            </Box>
            <Button
              variant="contained"
              className={`${styles.btn} ${styles.btnCreate}`}
              onClick={() => setCreateStatus(true)}
            >
              <Add fontSize="large" />
              <Typography className={styles.btnText}>add new user</Typography>
            </Button>
          </Box>
        </Box>
        <UserTable
          createStatus={createStatus}
          setCreateStatus={setCreateStatus}
          editableStatus={editableStatus}
          setEditableStatus={setEditableStatus}
          users={users}
          byGroup={!!params.id}
          refetchUsers={refetchUsers}
          searchValue={searchValue}
        />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={error}
        message="Error: VM Data not found by hash"
        autoHideDuration={6000}
        className={styles.snackBar}
        onClose={() => setError(false)}
      />
      {loading && (
        <Box className={styles.loaderWrapper}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

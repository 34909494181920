import { Dispatch, SetStateAction, useCallback, useState } from 'react'

type UseAsync<I extends unknown[], O> = {
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setData: Dispatch<SetStateAction<O | null>>
  run: (...args: I) => Promise<void>
  data: O | null
}

export const useAsync = <I extends unknown[], O>(
  getFunction: (...args: I) => Promise<O>
): UseAsync<I, O> => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [data, setData] = useState<O | null>(null)
  const run = useCallback(
    async (...args: I) => {
      try {
        setLoading(true)
        const data = await getFunction(...args)
        if (!data) {
          throw new Error('Data not found')
        }
        setData(data)
      } catch (error) {
        setError(true)
        setData(null)
      } finally {
        setLoading(false) // note setLoading in finally to stop loading if errors
      }
    },
    [getFunction]
  )

  return {
    error,
    setError,
    loading,
    setLoading,
    setData,
    run,
    data,
  }
}

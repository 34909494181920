import { Card, CardMedia, CardContent, Typography } from '@material-ui/core'
import polaroidStaticImg from '../assets/polaroidPreview/polaroidStaticImg.png'
import polaroidStaticLogo from '../assets/polaroidPreview/polaroidStaticLogo.png'
import noBrandLogo from '../assets/polaroidPreview/noBrandLogo.png'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    polaroidPreview: {
      width: '20vw',
    },
    container: {
      display: 'grid',
      gridTemplateColumns: '1.5fr 1fr',
    },
    logosWrapper: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr',
      alignItems: 'center',
    },
    polaroidImg: {
      height: '75%',
    },
    polaroidLogosContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    polaroidLogos: {
      maxWidth: '100%',
    },
    polaroidGlassesLogo: {
      maxWidth: '70%',
    },
  })
)

interface PolaroidPreviewProps {
  style: string
  currentLogo: string
}

const PolaroidPreview: React.FC<PolaroidPreviewProps> = ({
  style,
  currentLogo,
}) => {
  const styles = useStyles()
  return (
    <Card className={styles.polaroidPreview}>
      <CardMedia
        src={polaroidStaticImg}
        alt="polaroid-static"
        component="img"
      />
      <CardContent className={styles.container}>
        <div>
          <Typography style={{ paddingBottom: '1rem', lineHeight: '2.8rem' }}>
            <b>MODEL NAME</b>
          </Typography>
          <Typography>Brand Name</Typography>
          <Typography>Frame Color</Typography>
          <Typography>Lens Color</Typography>
        </div>
        <div className={styles.logosWrapper}>
          <div className={styles.polaroidLogosContainer}>
            <img
              className={styles.polaroidLogos}
              src={
                style === 'No logo template' || style === ''
                  ? noBrandLogo
                  : currentLogo
              }
              alt="brand-logo"
            />
          </div>
          <div className={styles.polaroidLogosContainer}>
            <img
              className={styles.polaroidGlassesLogo}
              src={polaroidStaticLogo}
              alt="static-logo"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default PolaroidPreview

import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { QRCodeGenerated } from '../components/QRCodeGenerated'
import { useDispatch } from 'react-redux'
import { resetApp } from '../features/reset/resetAppSlice'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../app/routers'
import { useSelector } from 'react-redux'
import { selectVmDataExperience } from '../features/vmdataSelectors'
import { createHash, createShortUrl } from '../features/qrCode/qrCodeSlice'
import {
  selectIsLoadingHash,
  selectVmUrl,
  selectShortUrl,
  selectQrCodetUrl,
} from '../features/qrCodeSelectors'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

export const EditConfirmationPage: React.FC = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const isLoadingHash = useSelector(selectIsLoadingHash)
  const vmUrl = useSelector(selectVmUrl)
  const vmDataExperience = useSelector(selectVmDataExperience)

  const shortUrl = useSelector(selectShortUrl)
  const qrCodeUrl = useSelector(selectQrCodetUrl)

  const isValidVmDataExperience =
    vmDataExperience &&
    vmDataExperience.name &&
    vmDataExperience.products.length > 0

  const handleConfirm = () => {
    dispatch(resetApp())
    history.push(AppRoutes.intro())
  }

  useEffect(() => {
    if (!vmUrl && !isLoadingHash && isValidVmDataExperience) {
      const products = vmDataExperience.products
      const hasTransitions = products.some((p) => p.isTransition)

      let vmData = {
        ...vmDataExperience,
        transitionFeature: { enabled: hasTransitions },
      }

      dispatch(createHash(vmData))
    }
  }, [
    isLoadingHash,
    isValidVmDataExperience,
    dispatch,
    vmDataExperience,
    vmUrl,
  ])

  useEffect(() => {
    if (vmUrl && !shortUrl?.isLoading && !shortUrl?.key) {
      dispatch(createShortUrl(vmUrl))
    }
  }, [dispatch, vmUrl, shortUrl])

  return (
    <Box className={styles.container}>
      {qrCodeUrl && shortUrl && !shortUrl.isLoading && (
        <QRCodeGenerated
          qrCodeUrl={qrCodeUrl}
          vmUrl={vmUrl}
          handleConfirm={handleConfirm}
        />
      )}
    </Box>
  )
}

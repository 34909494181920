import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Actions } from './core/Actions'
import { Product } from '../types/vmdata'
import { useDispatch, useSelector } from 'react-redux'
import { colorPalette } from '../styleVariables'
import { useProductListUpdate } from '../hooks/useProductListUpdate'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { replaceProducts } from '../features/products/productSlice'

interface Column {
  id: 'index' | 'code' | 'colorCode' | 'thumbnailUrl' | 'actions' | 'size'
  label: string
  minWidth?: number
}

const columns: Column[] = [
  { id: 'index', label: '#', minWidth: 100 },
  { id: 'code', label: 'Model', minWidth: 150 },
  { id: 'colorCode', label: 'Color', minWidth: 150 },
  { id: 'size', label: 'Size', minWidth: 100 },
  { id: 'thumbnailUrl', label: 'Image', minWidth: 150 },
  { id: 'actions', label: 'Actions', minWidth: 150 },
]

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: '62vh',
    maxHeight: '62vh',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    overflow: 'auto',
  },
  rowHead: {
    display: 'grid',
    gridTemplateColumns: '1.5fr repeat(5, 2fr)',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
  },
  rowBody: {
    display: 'grid',
    gridTemplateColumns: '1.5fr repeat(5, 2fr)',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000029',
    borderRadius: '0px 0px 2px 2px',
  },
  columnHeadCell: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  columnRowCell: {
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0.65rem 1.35rem',
  },
  img: {
    width: '4.55rem',
  },
  emptyMessageWrapper: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exmptyMessage: {
    fontSize: '2rem',
    color: colorPalette.greyDark,
  },
  animation: {
    animation: 'target-fade 2s ease-in',
  },
})

const EmptyMessage: React.FC = () => {
  const styles = useStyles()

  return (
    <Box className={styles.emptyMessageWrapper}>
      <Typography className={styles.exmptyMessage}>
        Your product list is empty!
      </Typography>
      <Typography className={styles.exmptyMessage}>
        Please fill it with some cool pair of glasses
      </Typography>
    </Box>
  )
}

interface ProductTableProps {
  onRemove: (product: Product) => void
  onEdit: (product: Product) => void
}

export const ProductsTable: React.FC<ProductTableProps> = ({
  onRemove,
  onEdit,
}) => {
  const styles = useStyles()
  const products = useSelector((state) => state.vmData.products)
  const productUpdate = useProductListUpdate(products)
  const dispatch = useDispatch()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer

    userSelect: 'none',
    width: '100%',
    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle,
  })
  const reorder = (list: Product[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    dispatch(replaceProducts(result))
  }

  const onDragEnd = (result: any) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      reorder(products, source.index, destination.index)
    }
  }

  useEffect(() => {
    if (scrollContainerRef.current && productUpdate.length > 0) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [productUpdate])

  return (
    <Paper className={styles.root}>
      <div className={styles.container} ref={scrollContainerRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={styles.rowHead}>
              {columns.map((column) => (
                <TableCell
                  className={styles.columnHeadCell}
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="products">
              {(provided, snapshot) => (
                <TableBody ref={provided.innerRef}>
                  {products.map((row: Product, ind) => {
                    return (
                      <Draggable
                        key={row.upc}
                        draggableId={row.upc}
                        index={ind}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            tabIndex={-1}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className={`${styles.rowBody} ${
                              (productUpdate.some((p) => p.upc === row.upc) &&
                                styles.animation) ||
                              ''
                            }`}
                          >
                            <TableCell className={styles.columnRowCell}>
                              {ind + 1}
                            </TableCell>
                            <TableCell className={styles.columnRowCell}>
                              {row.code}
                            </TableCell>
                            <TableCell className={styles.columnRowCell}>
                              {row.colorCode}
                            </TableCell>
                            <TableCell className={styles.columnRowCell}>
                              {row.size}
                            </TableCell>
                            <TableCell className={styles.columnRowCell}>
                              <img
                                src={row.thumbnailUrl}
                                alt={row.styleName}
                                className={styles.img}
                              />
                            </TableCell>
                            <TableCell className={styles.columnRowCell}>
                              <Actions
                                remove={() => onRemove(row)}
                                edit={() => onEdit(row)}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    )
                  })}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
        {!products.length && <EmptyMessage />}
      </div>
    </Paper>
  )
}

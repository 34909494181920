import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserSlice } from '../../types/user'
import userServices from '../../services/userServices'

const initialState: UserSlice = {
  isLoading: false,
  hasError: false,
  userName: localStorage.getItem('userName') || '',
}

export const getToken = createAsyncThunk(
  'vm-bo/user/getToken',
  (code: string) => userServices.getJwtToken(code)
)

export const userLogout = createAsyncThunk('vm-bo/user/logout', () =>
  userServices.userLogout()
)

const user = createSlice({
  name: 'vm-bo/user',
  initialState,
  reducers: {},
  extraReducers: {
    [getToken.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false
      state.hasError = true
    },
    [getToken.fulfilled.toString()]: (state, { payload }) => {
      localStorage.setItem('userToken', payload)
      const base64Url = payload.split('.')[1]
      const jsonPayload = decodeURIComponent(atob(base64Url))
      const tokenData = JSON.parse(jsonPayload)
      localStorage.setItem('userName', tokenData.name)
      state.userName = tokenData.name
      state.isLoading = false
      state.hasError = false
    },
    [getToken.pending.toString()]: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    [userLogout.fulfilled.toString()]: (state) => {
      state.isLoading = false
      state.hasError = false
      state.userName = ''
    },
    [userLogout.pending.toString()]: (state) => {
      state.isLoading = true
      state.hasError = false
    },
    [userLogout.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false
      state.hasError = true
    },
  },
})

// export const {} = qrCode.actions
export default user.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { Actions } from '../../components/core/Actions'
import { Analytics } from '../../types/vmdata'

const initialState: Analytics = {
  adobeSessionId: undefined,
  pagePlatform: 'QRcodePlatform',
  pageBrand: undefined,
  storeId: undefined,
  storeRegion: undefined,
  storeCompany: undefined,
  sourcePosition: undefined,
  pageEnvironment: undefined,
  storeGlobalId: undefined,
  source: 'qrcode',
}

const analytics = createSlice({
  name: 'vm-bo/analytics',
  initialState,
  reducers: {
    setAnalyticsData: (state, action: PayloadAction<Analytics | undefined>) => {
      return (state = { ...state, ...action.payload })
    },
    setChannelData: (state, action: PayloadAction<string>) => {
      return (state = { ...state, pageBrand: action.payload })
    },
    setRegionData: (state, action: PayloadAction<string>) => {
      return (state = { ...state, storeRegion: action.payload })
    },
    setStyleData: (state, action: PayloadAction<string>) => {
      return (state = { ...state, storeCompany: action.payload })
    },
    setStoreIdData: (state, action: PayloadAction<string>) => {
      return (state = { ...state, storeId: action.payload })
    },
  },
})

export const {
  setAnalyticsData,
  setChannelData,
  setStyleData,
  setRegionData,
  setStoreIdData,
} = analytics.actions
export default analytics.reducer

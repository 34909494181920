import {
  TableBody,
  TableRow,
  TableCell,
  Input,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { Delete, Check, Edit, Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { colorPalette } from '../../styleVariables'
import { Users } from '../UserList'
import { Group } from '../ListingGroups'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '70vh',
    minHeight: '70vh',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    overflow: 'auto',
    position: 'relative',
  },
  rowHead: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
  },
  rowBody: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000029',
    width: '100%',
    borderRadius: '0px 0px 2px 2px',
  },
  columnHeadCell: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  columnRowCell: {
    fontSize: '1.1rem',
    maxWidth: '16%',
    padding: '0.65rem 1.35rem',
  },
  cellSpan: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '250px  ',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    color: ' #fff',
    padding: ' 4px    8px',
    fontsize: ' 0.625rem',
    maxWidth: ' 300px',
    wordwrap: ' break-word',
    fontFamily: ' Roboto,serif',
    fontWeight: 500,
    lineHeight: ' 1.4em',
    borderRadius: '8px',
    backgroundColor: 'rgba(97, 97, 97, 1)',
  },
  img: {
    width: '4.55rem',
  },
  svg: {
    fill: colorPalette.blue,
    width: '1.85rem',
    height: '1.85rem',
  },
  redSvg: {
    fill: 'red',
    width: '1.85rem',
    height: '1.85rem',
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
  textInput: {
    fontSize: '1.1rem',
  },
  content: {
    padding: '3rem',
    position: 'relative',
    minWidth: '40vw',
  },
  title: {
    fontSize: '2.35rem',
    fontWeight: 700,
  },
  messageWrapper: {
    margin: '3rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

interface UserTableBodyProps {
  createStatus: boolean
  handleAddGroupName: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => void
  handleAddGroupNameSelect: (value: string, field: string) => void
  handleChangeGroupName: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => void
  handleChangeGroupNameSelect: (value: string, field: string) => void
  roles: string[]
  availableGroups: Group[] | undefined
  users: Users[] | undefined
  setCreateStatus: (value: boolean) => void
  handleAddGroup: () => void
  searchValue: string
  setRowToEdit: (row: Users) => void
  rowToAdd: Users
  rowToEdit: Users
  onOpenPopup: (row: Users) => void
  handleEdit: (row: Users) => void
  editableStatus: boolean
  setEditableStatus: (value: boolean) => void
}

export const UserTableBody: React.FC<UserTableBodyProps> = ({
  setEditableStatus,
  setRowToEdit,
  setCreateStatus,
  handleEdit,
  onOpenPopup,
  rowToAdd,
  createStatus,
  editableStatus,
  handleAddGroup,
  handleAddGroupName,
  handleChangeGroupName,
  handleChangeGroupNameSelect,
  searchValue,
  rowToEdit,
  roles,
  users,
  handleAddGroupNameSelect,
  availableGroups,
}) => {
  const styles = useStyles()
  return (
    <>
      <TableBody>
        {createStatus && (
          <TableRow role="checkbox" tabIndex={-1} className={styles.rowBody}>
            <TableCell className={styles.columnRowCell}>
              <Input
                className={styles.textInput}
                onChange={(ev) => handleAddGroupName(ev, 'username')}
              />
            </TableCell>
            <TableCell className={styles.columnRowCell}>
              <Input
                className={styles.textInput}
                onChange={(ev) => handleAddGroupName(ev, 'surname')}
              />
            </TableCell>
            <TableCell className={styles.columnRowCell}>
              <Input
                className={styles.textInput}
                onChange={(ev) => handleAddGroupName(ev, 'name')}
              />
            </TableCell>
            <TableCell className={styles.columnRowCell}>
              <Input
                className={styles.textInput}
                onChange={(ev) => handleAddGroupName(ev, 'email')}
              />
            </TableCell>
            <TableCell className={styles.columnRowCell}>
              <FormControl>
                <Select
                  onChange={(ev) =>
                    handleAddGroupNameSelect(ev.target.value as string, 'role')
                  }
                  disableUnderline={true}
                  className={styles.textInput}
                  value={rowToAdd.role}
                  displayEmpty
                  renderValue={
                    rowToAdd.role !== '' ? undefined : () => 'Select'
                  }
                >
                  {roles.map((role, key) => (
                    <MenuItem key={key} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={styles.columnRowCell}>
              <FormControl>
                <Select
                  disableUnderline={true}
                  value={rowToAdd.groupId}
                  className={styles.textInput}
                  displayEmpty
                  renderValue={
                    rowToAdd.groupId !== '' ? undefined : () => 'Select'
                  }
                  onChange={(ev) =>
                    handleAddGroupNameSelect(
                      ev.target.value as string,
                      'groupId'
                    )
                  }
                >
                  {availableGroups?.map((r, k) => (
                    <MenuItem key={k} value={r.id}>
                      {r.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={styles.columnRowCell}>
              <Input className={styles.textInput} disabled />
            </TableCell>
            <TableCell className={styles.columnRowCell}>
              <IconButton onClick={() => setCreateStatus(false)}>
                <Delete className={styles.redSvg} />
              </IconButton>
              <IconButton onClick={handleAddGroup}>
                <Check className={styles.svg} />
              </IconButton>
            </TableCell>
          </TableRow>
        )}
        {users
          ?.filter(
            (row) =>
              row.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
              row.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
              row.surname?.toLowerCase().includes(searchValue.toLowerCase()) ||
              row.email?.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((row, ind) => (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={ind}
              className={styles.rowBody}
            >
              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell}>
                  <Tooltip
                    TransitionProps={{ style: { fontSize: '1.1rem' } }}
                    title={row.username}
                    aria-label={row.username}
                  >
                    <span className={styles.cellSpan}>{row.username}</span>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell className={styles.columnRowCell}>
                  <Input
                    className={styles.textInput}
                    onChange={(ev) => handleChangeGroupName(ev, 'username')}
                    value={rowToEdit.username}
                  />
                </TableCell>
              )}
              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell}>
                  <Tooltip
                    TransitionProps={{ style: { fontSize: '1.1rem' } }}
                    title={row.surname}
                    aria-label={row.surname}
                  >
                    <span className={styles.cellSpan}>{row.surname}</span>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell className={styles.columnRowCell}>
                  <Input
                    className={styles.textInput}
                    onChange={(ev) => handleChangeGroupName(ev, 'surname')}
                    value={rowToEdit.surname}
                  />
                </TableCell>
              )}
              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell}>
                  <Tooltip
                    TransitionProps={{ style: { fontSize: '1.1rem' } }}
                    title={row.name}
                    aria-label={row.name}
                  >
                    <span className={styles.cellSpan}>{row.name}</span>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell className={styles.columnRowCell}>
                  <Input
                    className={styles.textInput}
                    onChange={(ev) => handleChangeGroupName(ev, 'name')}
                    value={rowToEdit.name}
                  />
                </TableCell>
              )}
              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell}>
                  <Tooltip
                    TransitionProps={{ style: { fontSize: '1.1rem' } }}
                    title={row.email}
                    aria-label={row.email}
                  >
                    <span className={styles.cellSpan}>{row.email}</span>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell className={styles.columnRowCell}>
                  <Input
                    className={styles.textInput}
                    onChange={(ev) => handleChangeGroupName(ev, 'email')}
                    value={rowToEdit.email}
                  />
                </TableCell>
              )}

              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell}>
                  <Tooltip
                    TransitionProps={{ style: { fontSize: '1.1rem' } }}
                    title={row.role}
                    aria-label={row.role}
                  >
                    <span className={styles.cellSpan}>{row.role}</span>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell className={styles.columnRowCell}>
                  <FormControl>
                    <Select
                      disableUnderline={true}
                      value={rowToEdit.role}
                      className={styles.textInput}
                      displayEmpty
                      renderValue={
                        rowToEdit.role !== '' ? undefined : () => rowToEdit.role
                      }
                      onChange={(ev) =>
                        handleChangeGroupNameSelect(
                          ev.target.value as string,
                          'role'
                        )
                      }
                    >
                      {roles.map((role, key) => (
                        <MenuItem key={key} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              )}
              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell}>
                  <Tooltip
                    title={row.group?.name || ''}
                    aria-label={row.group?.name}
                  >
                    <span className={styles.cellSpan}>{row.group?.name}</span>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell className={styles.columnRowCell}>
                  <FormControl style={{ width: '100%' }}>
                    <Select
                      style={{ width: '100%' }}
                      disableUnderline={true}
                      value={rowToEdit.groupId}
                      className={styles.textInput}
                      displayEmpty
                      renderValue={
                        rowToEdit.groupId !== ''
                          ? undefined
                          : () => `${rowToEdit.group?.name}`
                      }
                      onChange={(ev) =>
                        handleChangeGroupNameSelect(
                          ev.target.value as string,
                          'groupId'
                        )
                      }
                    >
                      {availableGroups?.map((r, k) => (
                        <MenuItem key={k} value={r.id}>
                          {r.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
              )}

              <TableCell className={styles.columnRowCell}>
                <Tooltip
                  title={row.createdAt?.split('T').shift() || ''}
                  aria-label={row.createdAt?.split('T').shift()}
                >
                  <span className={styles.cellSpan}>
                    {row.createdAt?.split('T').shift()}
                  </span>
                </Tooltip>
              </TableCell>

              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell}>
                  <IconButton
                    onClick={() => {
                      setRowToEdit(row)
                      setEditableStatus(true)
                    }}
                  >
                    <Edit className={styles.svg} />
                  </IconButton>
                  <IconButton onClick={() => onOpenPopup(row)}>
                    <Close className={styles.svg} />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell className={styles.columnRowCell}>
                  <IconButton onClick={() => setEditableStatus(false)}>
                    <Delete className={styles.redSvg} />
                  </IconButton>
                  <IconButton onClick={() => handleEdit(row)}>
                    <Check className={styles.svg} />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </>
  )
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = ''

const reset = createSlice({
  name: 'vm-bo/reset-app',
  initialState,
  reducers: {
    resetApp: () => {
      return
    },
  },
})

export const { resetApp } = reset.actions
export default reset

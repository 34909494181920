import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Box,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { VpnKey as VpnKeyIcon } from '@material-ui/icons'
import { colorPalette } from '../styleVariables'
import { Logo } from '../components/Logo'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getToken } from '../features/user/userSlice'
import { AppRoutes } from '../app/routers'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: 'none',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage:
        'radial-gradient(farthest-corner at 50% 100% , #1C7CE0 0%, #150051 100%)',
      color: colorPalette.white,
      animation: 'filter-animation 6s infinite ease-in',
    },
    btn: {
      padding: '1rem 5.8rem',
      fontSize: '1.125rem',
      color: colorPalette.white,
      '&:hover': {
        backgroundColor: 'rgba(213, 213, 213, 0.5)',
      },
    },
    btnList: {
      backgroundColor: 'transparent',
      border: `2px solid ${colorPalette.white}`,
      marginBottom: '1rem',
    },
    btnCreate: {
      backgroundColor: colorPalette.blue,
    },
    btnText: {
      fontSize: '1.125rem',
      marginLeft: '1rem',
      fontWeight: 500,
    },
    logo: {
      width: '16.75rem',
    },
    loginIcon: {
      marginLeft: '1rem',
    },
  })
)

const {
  REACT_APP_IDM_URL,
  REACT_APP_LUXOTTICA_CLIENT_ID,
  REACT_APP_IDM_REDIRECT_URI,
} = process.env

export const LoginPage: React.FC = () => {
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false)
  const styles = useStyles()
  const isLoadingLogin = useSelector((state) => state.user.isLoading)
  const hasError = useSelector((state) => state.user.hasError)
  const history = useHistory()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const handleLogin = () => {
    if (
      REACT_APP_IDM_URL &&
      REACT_APP_LUXOTTICA_CLIENT_ID &&
      REACT_APP_IDM_REDIRECT_URI
    ) {
      const loginPage = queryString.stringifyUrl({
        url: `${REACT_APP_IDM_URL}/oauth/nam/authz`,
        query: {
          response_type: 'code',
          client_id: REACT_APP_LUXOTTICA_CLIENT_ID,
          redirect_uri: REACT_APP_IDM_REDIRECT_URI,
          scope: 'virtualmirror',
        },
      })

      window.open(loginPage, '_self')
    }
  }

  useEffect(() => {
    if (search) {
      const parsed = queryString.parse(search)

      // @ts-ignore
      if (parsed.code) {
        // @ts-ignore
        dispatch(getToken(parsed.code))
      }
    }
  }, [dispatch, search])

  useEffect(() => {
    if (!isLoadingLogin && localStorage.getItem('userToken')) {
      history.push(AppRoutes.intro())
    }
  }, [history, isLoadingLogin])

  useEffect(() => {
    setIsErrorVisible(hasError)
  }, [hasError])

  return (
    <Container className={styles.container} disableGutters>
      <Box m="5rem" className={styles.logo}>
        <Logo />
      </Box>
      <Box display="flex" flexDirection="column"></Box>
      <Button
        variant="contained"
        className={`${styles.btn} ${styles.btnCreate}`}
        onClick={handleLogin}
        startIcon={
          isLoadingLogin ? null : <VpnKeyIcon className={styles.loginIcon} />
        }
      >
        {isLoadingLogin ? (
          <CircularProgress color={'secondary'} />
        ) : (
          <Typography className={styles.btnText}>LOGIN</Typography>
        )}
      </Button>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isErrorVisible}
        autoHideDuration={6000}
        onClose={() => setIsErrorVisible(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          An error occured during login.
        </MuiAlert>
      </Snackbar>
    </Container>
  )
}

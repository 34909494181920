import { Close } from '@material-ui/icons'
import { IconButton, Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Group } from '../ListingGroups'
import React from 'react'
import { Alert } from '@material-ui/lab'

interface GroupsTablePopupProps {
  setDeletedStatus: (value: boolean) => void
  setRowToDelete: (row: Group | null) => void
  setEditableStatus: (value: boolean) => void
  setRowToEdit: (row: Group) => void
  setRowToAdd: (row: Group) => void
  setisEdited: (value: boolean) => void
  setisAdded: (value: boolean) => void
  setCreateStatus: (value: boolean) => void
  deletedStatus: boolean
  isEdited: boolean
  isAdded: boolean
  rowToAdd: Group
  rowToDelete: Group | null
  rowToEdit: Group | null
}

export const GroupsTablePopup: React.FC<GroupsTablePopupProps> = ({
  setDeletedStatus,
  setRowToDelete,
  setEditableStatus,
  setRowToEdit,
  setRowToAdd,
  setisEdited,
  setCreateStatus,
  setisAdded,
  deletedStatus,
  isEdited,
  isAdded,
  rowToAdd,
  rowToEdit,
  rowToDelete,
}) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deletedStatus}
        autoHideDuration={6000}
        onClose={() => {
          setDeletedStatus(false)
          setRowToDelete(null)
        }}
      >
        <Alert severity="success">
          {`${rowToDelete?.name} has been deleted`}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isEdited}
        autoHideDuration={6000}
        onClose={() => {
          setEditableStatus(false)
          setRowToEdit({ id: '', name: '' })
          setisEdited(false)
        }}
      >
        <Alert severity="success">
          {`${rowToEdit?.name} has been updated`}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isAdded}
        autoHideDuration={6000}
        onClose={() => {
          setCreateStatus(false)
          setRowToAdd({ id: '', name: '' })
          setisAdded(false)
        }}
      >
        <Alert severity="success">{`${rowToAdd.name} has been added`}</Alert>
      </Snackbar>
    </>
  )
}

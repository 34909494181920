export const getGen8 = (): string[] => [
  'SIGNATURE-GEN8-AMBER',
  'SIGNATURE-GEN8-AMETHYST',
  'SIGNATURE-GEN8-BROWN',
  'SIGNATURE-GEN8-EMERALD',
  'SIGNATURE-GEN8-GRAY',
  'SIGNATURE-GEN8-GGREEN',
  'SIGNATURE-GEN8-SAPPHIRE',
]

export const getXtractive = (): string[] => [
  'XTRACTIVE-NEWGEN-BLUE-MIRROR',
  'XTRACTIVE-NEWGEN-BROWN',
  'XTRACTIVE-NEWGEN-GREEN',
  'XTRACTIVE-GOLD-MIRROR',
  'XTRACTIVE-NEWGEN-GRAY',
  'XTRACTIVE-NEWGEN-GREEN-MIRROR',
  'XTRACTIVE-PINK-MIRROR',
  'XTRACTIVE-RED-MIRROR',
  'XTRACTIVE-SILVER-MIRROR',
]

export const getXtractivePolarized = (): string[] => [
  'XTRACTIVE-VANTAGE-POLARIZED',
]

export const getGenS = (): string[] => [
  'GENS-AMBER',
  'GENS-RUBY',
  'GENS-AMETHYST',
  'GENS-EMERALD',
  'GENS-GRAY',
  'GENS-SAPPHIRE',
  'GENS-BROWN',
  'GENS-GGREEN',
]

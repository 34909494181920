import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  CardMedia,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { LinkForm } from './LinkForm'
import { setLinksHeader } from '../features/links/linkHeaderSlice'
import { replaceLinks } from '../features/links/linkSlice'

import liveTryOn from '../assets/liveTryOn.png'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    overflow: 'auto',
    paddingBottom: '2rem',
  },
  rowHead: {
    background: '#fafafa',
    boxShadow: '0px 3px 6px #00000029',
  },
  linkHeaderCell: {
    paddingTop: '2rem',
    border: 0,
  },
  linkHeaderText: {
    background: 'white',
  },
  linkTitle: {
    fontSize: '1.5rem',
    fontWeight: 700,
    marginBottom: '0.66rem',
  },
  linkSection: {
    border: 0,
    verticalAlign: 'top',
  },
  columnHeadCell: {
    fontSize: '1.5rem',
    fontWeight: 700,
    width: '35%',
  },
  disabledElement: {
    opacity: 0.4,
  },
  previewWrapper: {
    position: 'relative',
    maxWidth: '400px',
    margin: '0 auto',
  },
  linkModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '60%',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 1.75rem',
    alignItems: 'center',
    '& h1': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '1.65rem',
      textAlign: 'center',
    },
    '& h2': {
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    '& .cta-wrapper': {
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      alignItems: 'center',
      justifyContent: 'end',
      '& .vmmv-cta': {
        width: '100%',
        borderRadius: '20px',
        '& span': {
          display: 'block',
          padding: '0px 6px',
          wordWrap: 'break-word',
        },
      },
      '& .vmmv-primary': {
        backgroundColor: '#000',
        color: '#ffffff',
        border: '1px solid #000',
      },
      '& .vmmv-secondary': {
        backgroundColor: '#ffffff',
        color: '#000',
        border: '1px solid #000',
      },
    },
  },
})

interface LinkTableProps {
  onValidated: (isValid: boolean) => void
}

export const LinksTable: React.FC<LinkTableProps> = ({ onValidated }) => {
  const [linkA, linkB] = useSelector((state) => state.vmData.links)
  const { title: linkTitle, subTitle: linkSubtitle } = useSelector(
    (state) => state.vmData.linksHeader
  )

  const styles = useStyles()
  const dispatch = useDispatch()

  const [title, setTitle] = useState(linkTitle || '')
  const [subtitle, setSubtitle] = useState(linkSubtitle || '')
  const [links, setLinks] = useState<any[]>([linkA, linkB])
  const [isLinkBValid, setLinkBValid] = useState(true)

  const isDisabled = title.length === 0
  const isLinksValid = useMemo(
    () => isDisabled || (!isDisabled && !!links[0] && isLinkBValid),
    [isDisabled, isLinkBValid, links]
  )

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch(
        setLinksHeader({
          title: title || undefined,
          subTitle: title.length > 0 ? subtitle || undefined : undefined,
        })
      )
    }, 100)

    return () => clearTimeout(debounce)
  }, [dispatch, subtitle, title])

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch(replaceLinks(title.trim().length === 0 ? [] : links))
    }, 100)

    return () => clearTimeout(debounce)
  }, [dispatch, links, title])

  useEffect(() => {
    onValidated(isLinksValid)
  }, [isLinksValid, onValidated])

  return (
    <Paper className={styles.root}>
      <div className={styles.container}>
        <Table>
          <TableHead className={styles.rowHead}>
            <TableRow>
              <TableCell className={styles.columnHeadCell}>Title</TableCell>
              <TableCell className={styles.columnHeadCell}>Subtitle</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={styles.linkHeaderCell}>
                <TextField
                  className={styles.linkHeaderText}
                  variant="outlined"
                  size="small"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  inputProps={{ maxLength: 44 }}
                  fullWidth
                />
              </TableCell>
              <TableCell className={styles.linkHeaderCell}>
                <TextField
                  className={`${styles.linkHeaderText} ${
                    isDisabled ? styles.disabledElement : ''
                  }`}
                  variant="outlined"
                  size="small"
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                  disabled={isDisabled}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                />
              </TableCell>
              <TableCell rowSpan={2}>
                <Box className={styles.previewWrapper}>
                  <CardMedia src={liveTryOn} component="img" alt="live-tryon" />
                  {title.length > 0 && !!linkA && (
                    <Paper className={styles.linkModal}>
                      <Typography component="h1">{title}</Typography>
                      <Typography component="h2">{subtitle}</Typography>
                      <Box className="cta-wrapper">
                        <Button
                          className="vmmv-cta vmmv-primary"
                          onClick={() => {
                            window.open(linkA.url, '_blank')
                          }}
                        >
                          {linkA.label}
                        </Button>
                        {!!linkB && !!linkB.url && (
                          <Button
                            className="vmmv-cta vmmv-secondary"
                            onClick={() => {
                              window.open(linkB.url, '_blank')
                            }}
                          >
                            {linkB.label}
                          </Button>
                        )}
                      </Box>
                    </Paper>
                  )}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow className={isDisabled ? styles.disabledElement : ''}>
              <TableCell colSpan={2} className={styles.linkSection}>
                <Typography className={styles.linkTitle}>
                  Link 1 (Required)
                </Typography>
                <LinkForm
                  data={linkA}
                  isDisabled={isDisabled}
                  onChange={(d) => setLinks((x) => [d, x[1]])}
                />
                <Typography className={styles.linkTitle}>
                  Link 2 (Optional)
                </Typography>
                <LinkForm
                  data={linkB}
                  isDisabled={isDisabled}
                  isRequired={false}
                  onChange={(d) => setLinks((x) => [x[0], d])}
                  onValidated={setLinkBValid}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Iframe from 'react-iframe'
import { OpenInNew } from '@material-ui/icons'
import QRCode from 'qrcode.react'
import { colorPalette } from '../styleVariables'
import copy from 'copy-to-clipboard'

interface QRCodeGeneratedProps {
  handleConfirm: () => void
  qrCodeUrl?: string
  vmUrl?: string
  title?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80%',
    },
    title: {
      color: colorPalette.black,
      fontWeight: 700,
      marginBottom: '2rem',
    },
    boxImage: {
      marginRight: '4rem',
      boxShadow: '5 5 5 5 #ccc',
    },
    iFrame: {
      height: 600,
      width: 415,
      borderRadius: 8,
    },
    btnOpenExperience: {
      marginBottom: '1rem',
    },
    btnTryExperience: {
      color: colorPalette.blue,
      marginLeft: '1rem',
      fontWeight: 600,
    },
    btnOk: {
      fontWeight: 600,
    },
    loaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 200,
      height: 200,
    },
    titleSaveQrCode: {
      fontWeight: 500,
      marginBottom: '1rem',
    },
    qrContainer: {
      '& > svg': {
        display: 'none',
      },
      '& canvas': {
        maxWidth: '200px',
        maxHeight: '200px',
      },
    },
    selectFormat: {
      marginRight: '1rem',
      width: 120,
      height: 40,
    },
    btnSaveFormat: {
      height: 35,
      width: 70,
    },
    inputLabelSelect: {
      marginBottom: '-0.6rem',
    },
  })
)

interface CopyTextProps {
  label: string
  text: string
}

const CopyText: React.FC<CopyTextProps> = ({ label, text }) => {
  const copyOnClick = () => {
    copy(text)
  }
  return (
    <Box paddingTop={4}>
      <Button title="click to copy the url " onClick={copyOnClick}>
        <span>{label}</span>&nbsp;
        <FileCopyOutlinedIcon />
      </Button>
      <Box paddingLeft={1}>{text}</Box>
    </Box>
  )
}

interface SaveQrCodeProps {
  title: string
}

const SaveQrCode: React.FC<SaveQrCodeProps> = ({ title }) => {
  const styles = useStyles()
  const formats = [
    {
      value: 'jpg',
      name: '.jpg',
      getUrl: () => {
        const qrCanvas = document.querySelector(
          '.qrContainer > canvas'
        ) as HTMLCanvasElement
        return qrCanvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')
      },
    },
    {
      value: 'svg',
      name: '.svg',
      getUrl: () => {
        const qrSvg = document.querySelector('.qrContainer > svg') as SVGElement
        const serializer = new XMLSerializer()
        const source =
          '<?xml version="1.0" standalone="no"?>\r\n' +
          serializer.serializeToString(qrSvg)
        return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source)
      },
    },
  ]
  const [selectedFormat, setSelectedFormat] = useState(formats[0])
  const saveQrCode = () => {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = selectedFormat.getUrl()
    a.download = `${title}.${selectedFormat.value}`
    document.body.appendChild(a)
    a.click()
    a.remove()
  }
  const handleFormatChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedFormat = formats.find(
      (f) => f.value === (event.target.value as string)
    )

    if (selectedFormat) {
      setSelectedFormat(selectedFormat)
    }
  }
  return (
    <Box paddingTop={4}>
      <Box paddingLeft={1}>
        <Typography variant="body2" className={styles.titleSaveQrCode}>
          SAVE THE QR CODE
        </Typography>
        <InputLabel
          id="select-style-label"
          shrink
          className={styles.inputLabelSelect}
        >
          Saving format
        </InputLabel>
        <Select
          labelId="select-style-label"
          id="select-style"
          value={selectedFormat.value}
          onChange={handleFormatChange}
          label="Format"
          className={styles.selectFormat}
        >
          {formats.map((format) => (
            <MenuItem key={format.value} value={format.value}>
              {format.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          title="save"
          color="primary"
          variant="contained"
          onClick={saveQrCode}
          className={styles.btnSaveFormat}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

export const QRCodeGenerated: React.FC<QRCodeGeneratedProps> = ({
  handleConfirm,
  qrCodeUrl,
  vmUrl,
  title = 'QR CODE GENERATED!',
}) => {
  const styles = useStyles()
  const openExperience = () => {
    window.open(qrCodeUrl, '_blank')
  }

  return (
    <Box flexDirection="column" alignItems="flex-start">
      <Typography variant="h4" className={styles.title}>
        {title}
      </Typography>
      <Box display="flex">
        <Box display="flex" className={styles.boxImage}>
          {vmUrl && (
            <Iframe
              url={vmUrl || ''}
              className={styles.iFrame}
              allow="camera *;microphone *"
            />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {qrCodeUrl && vmUrl ? (
              <>
                <Button
                  className={styles.btnOpenExperience}
                  onClick={openExperience}
                >
                  <OpenInNew color="primary" />
                  <Typography variant="h6" className={styles.btnTryExperience}>
                    Try experience
                  </Typography>
                </Button>
                <Box className={`qrContainer ${styles.qrContainer}`}>
                  <QRCode size={600} value={qrCodeUrl} data-url={qrCodeUrl} />
                  <QRCode
                    size={600}
                    value={qrCodeUrl}
                    data-url={qrCodeUrl}
                    renderAs="svg"
                  />
                </Box>
                <SaveQrCode title={title} />
                <CopyText label="DUPLICATE THE EXPERIENCE" text={vmUrl} />
                <CopyText label="SHARE THE EXPERIENCE" text={qrCodeUrl} />
              </>
            ) : (
              <Box className={styles.loaderWrapper}>
                <CircularProgress />
              </Box>
            )}
          </Box>

          <Button
            color="primary"
            variant="contained"
            className={styles.btnOk}
            onClick={handleConfirm}
          >
            <Typography variant="h6">OK</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

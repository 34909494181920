import React, { useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Actions } from './core/Actions'
import { colorPalette } from '../styleVariables'
import { VMListData } from '../types/vmlistingdata'
import { Visibility, Warning } from '@material-ui/icons'
import { format, isValid } from 'date-fns'
import { shallowEqual, useSelector } from 'react-redux'
import { Popup } from './Popup'
import { VMDataExperience } from '../types/vmdata'
import { SearchFilter } from './SearchFilter'
import fifteenAlert from '../assets/15Alert.png'
interface Column {
  id:
    | 'index'
    | 'name'
    | 'createdAt'
    | 'expireDate'
    | 'preview'
    | 'actions'
    | 'createdBy'
  label: string
  minWidth?: number
  filterable?: boolean
  formatDate?: string
}

const columns: Column[] = [
  {
    id: 'createdBy',
    label: 'Last modified by',
    filterable: true,
    minWidth: 145,
  },
  { id: 'name', label: 'Name', filterable: true },
  {
    id: 'createdAt',
    label: 'Last Revision Date',
    minWidth: 160,
    filterable: true,
    formatDate: 'dd/MM/yyyy',
  },
  {
    id: 'expireDate',
    label: 'Expiration Date',
    filterable: true,
    formatDate: 'dd/MM/yyyy HH:mm',
    minWidth: 185,
  },
  { id: 'preview', label: 'Preview' },
  { id: 'actions', label: 'Actions', minWidth: 125 },
]

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: '70vh',
    maxHeight: '70vh',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    overflow: 'auto',
    position: 'relative',
  },
  rowHead: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
  },
  rowBody: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000029',
    borderRadius: '0px 0px 2px 2px',
  },
  columnHeadCell: {
    fontSize: '1.1rem',
    fontWeight: 700,
    '& #index-label, #createdAt-label, #createdBy-label, #name-label, #expireDate-label, #preview-label, #actions-label': {
      fontSize: '1rem',
    },
  },
  columnRowCell: {
    fontSize: '1.1rem',
    padding: '0.65rem 1.35rem',
  },
  tooltip: {
    color: ' #fff',
    padding: ' 4px    8px',
    fontsize: ' 0.625rem',
    maxWidth: ' 300px',
    wordwrap: ' break-word',
    fontFamily: ' Roboto,serif',
    fontWeight: 500,
    lineHeight: ' 1.4em',
    borderRadius: '8px',
    backgroundColor: 'rgba(97, 97, 97, 1)',
  },
  img: {
    width: '4.55rem',
  },
  emptyMessageWrapper: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exmptyMessage: {
    fontSize: '2rem',
    color: colorPalette.greyDark,
  },
  svg: {
    fill: colorPalette.blue,
    width: '1.85rem',
    height: '1.85rem',
    verticalAlign: 'middle',
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
})

const EmptyMessage: React.FC = () => {
  const styles = useStyles()

  return (
    <Box className={styles.emptyMessageWrapper}>
      <Typography className={styles.exmptyMessage}>
        Your list is empty!
      </Typography>
    </Box>
  )
}

interface ListingTableProps {
  onRemove: (data: VMListData) => void
  onCreateOrEdit: (
    hash: string,
    isEdit: boolean,
    data?: VMDataExperience
  ) => void
  onPreview: (data: VMListData) => void
}

interface FiltersState {
  filters: {
    [key: string]: any
  }
}

const initialState: FiltersState = {
  filters: {},
}
const reducer = (
  state: FiltersState,
  action: {
    [key: string]: any
  }
) => {
  return { filters: { ...state.filters, ...action } }
}
export const ListingTable: React.FC<ListingTableProps> = ({
  onRemove,
  onCreateOrEdit,
  onPreview,
}) => {
  const styles = useStyles()
  const [filters, updateFilters] = useReducer(reducer, initialState)
  const { listing, loading, search } = useSelector(
    (state) => ({
      listing: state.listing.listingData,
      loading: state.listing.loading,
      search: state.listing.search,
    }),
    shallowEqual
  )

  function listingsFilter(listing: VMListData) {
    const doesIncludeSearch = (text: string) => {
      return text.toLowerCase().includes(search?.toLowerCase() || '')
    }
    return !search
      ? true
      : doesIncludeSearch(listing.name) ||
          doesIncludeSearch(listing.createdBy || '')
  }

  const columnFilter = (l: VMListData, column: Column) => {
    if (
      !filters.filters[column.id] ||
      filters.filters[column.id].length === 0
    ) {
      return true
    }
    if (
      column.formatDate &&
      filters.filters[column.id]?.some(
        (d: string) => new Date(d) instanceof Date
      )
    ) {
      return filters.filters[column.id]?.some(
        (d: string) =>
          format(
            new Date(l[column.id as keyof VMListData] as string),
            column.formatDate as string
          ) === format(new Date(d), column.formatDate as string)
      )
    }
    return filters.filters[column.id].includes(l[column.id as keyof VMListData])
  }
  const filteredListings = columns
    .filter((c) => c.filterable)
    .reduce((acc, column) => {
      return acc.filter((l) => columnFilter(l, column))
    }, listing || [])
    .filter(listingsFilter)
  let lastOptions = listing
  const options: { [key: string]: any[] } = columns
    .filter((c) => c.filterable)
    .reduce((acc, v) => {
      const initialOptions = lastOptions?.map(
        (l: VMListData) => l[v.id as keyof VMListData]
      )
      lastOptions = lastOptions?.filter((l) => columnFilter(l, v))

      function onlyUnique(value: any, index: number, self: any[]) {
        return self.indexOf(value) === index
      }

      function uniqueWithFormat(array: any[]) {
        const object = array.reduce((acc, value) => {
          return {
            ...acc,
            [v.formatDate && value
              ? format(new Date(value), v.formatDate)
              : value]: value,
          }
        }, {})
        return Object.keys(object).map((k) => object[k])
      }

      return {
        ...acc,
        [v.id]: v.formatDate
          ? uniqueWithFormat(initialOptions || [])
          : initialOptions?.filter(onlyUnique),
      }
    }, {})
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [rowToDelete, setRowToDelete] = useState<VMListData | null>(null)

  const onOpenPopup = (row: VMListData) => {
    setRowToDelete(row)
    setIsOpenPopup(true)
  }

  const onClosePopup = () => {
    setRowToDelete(null)
    setIsOpenPopup(false)
  }

  const onRemoveRow = () => {
    if (rowToDelete) onRemove(rowToDelete)
  }

  return (
    <>
      <Paper className={styles.root}>
        <div className={styles.container}>
          <Table stickyHeader>
            <TableHead className={styles.rowHead}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className={styles.columnHeadCell}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {
                      <FormControl fullWidth>
                        <SearchFilter
                          options={options[column.id] || []}
                          disabled={!column.filterable}
                          onUpdate={(v) => updateFilters({ [column.id]: v })}
                          value={filters.filters[column.id] || []}
                          formatDate={column.formatDate}
                          column={column}
                        />
                      </FormControl>
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredListings?.map((row: VMListData, ind) => {
                const expIsValid = isValid(new Date(row.expireDate))
                const expired = !(
                  expIsValid && new Date(row.expireDate) > new Date()
                )
                const expiring =
                  expIsValid &&
                  !expired &&
                  new Date(row.expireDate).getTime() - new Date().getTime() <
                    1000 * 60 * 60 * 24 * 7 /* one week in milliseconds */
                return (
                  <TableRow
                    role="checkbox"
                    tabIndex={-1}
                    key={ind}
                    className={styles.rowBody}
                  >
                    <TableCell className={styles.columnRowCell}>
                      {row.createdBy}
                    </TableCell>
                    <TableCell className={styles.columnRowCell}>
                      {row.name}
                    </TableCell>
                    <TableCell className={styles.columnRowCell}>
                      {row.createdAt &&
                        format(new Date(row.createdAt), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell className={styles.columnRowCell}>
                      {expIsValid
                        ? format(new Date(row.expireDate), 'dd/MM/yyyy hh:mm')
                        : row.expireDate}
                      {expiring && (
                        <Tooltip
                          PopperComponent={(props) => (
                            <Popper {...props}>
                              <div className={styles.tooltip}>
                                <Typography color="inherit">
                                  The experience is about to expire. If you wish
                                  to keep it live, postpone the Expiration Date
                                  Time
                                </Typography>
                              </div>
                            </Popper>
                          )}
                          title="ignored"
                        >
                          <IconButton>
                            <Warning className={styles.svg} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell className={styles.columnRowCell}>
                      <IconButton onClick={() => onPreview(row)}>
                        <Visibility className={styles.svg} />
                      </IconButton>
                      {row?.data && row?.data?.products?.length >= 15 && (
                        <img
                          className={styles.svg}
                          src={fifteenAlert}
                          alt="static-logo"
                        />
                      )}
                    </TableCell>
                    <TableCell className={styles.columnRowCell}>
                      <Actions
                        remove={expired ? () => onOpenPopup(row) : undefined} // show remove only on expired experience
                        edit={() => onCreateOrEdit(row.token, true, row.data)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {loading && (
            <Box className={styles.loaderWrapper}>
              <CircularProgress />
            </Box>
          )}
          {!listing?.length && <EmptyMessage />}
        </div>
      </Paper>
      <Popup
        isOpen={isOpenPopup}
        onClose={onClosePopup}
        onConfirm={onRemoveRow}
        title="Delete experience"
        message="Are you sure you want to delete the experience?"
      />
    </>
  )
}

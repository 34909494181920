import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product } from '../../types/vmdata'

const initialState: Product[] = []

const products = createSlice({
  name: 'vm-bo/products',
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<Product>) => {
      const product = state.find(
        (product) => product.upc === action.payload.upc
      )
      return !product ? [...state, action.payload] : state
    },
    removeProduct: (state, action: PayloadAction<Product>) => {
      return state.filter((product) => product.upc !== action.payload.upc)
    },
    updateProduct: (state, action: PayloadAction<Product>) => {
      const productIndex = state.findIndex(
        (product) => product.upc === action.payload.upc
      )
      state[productIndex] = action.payload
    },
    replaceProducts: (_state, action: PayloadAction<Product[]>) => {
      return action.payload
    },
    addProducts: (state, action: PayloadAction<Product[]>) => {
      const setObj = new Set()
      const uniqueProducts = [...state, ...action.payload].reduce(
        (acc: Product[], item) => {
          if (!setObj.has(item.upc)) {
            setObj.add(item.upc)
            acc.push(item)
          }
          return acc
        },
        []
      )
      return uniqueProducts
    },
  },
})

export const {
  addProduct,
  addProducts,
  removeProduct,
  updateProduct,
  replaceProducts,
} = products.actions
export default products.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const name = createSlice({
  name: 'vm-bo/name',
  initialState,
  reducers: {
    setExperienceName: (_, action: PayloadAction<string | undefined>) => {
      return action.payload || ''
    },
  },
})

export const { setExperienceName } = name.actions
export default name.reducer

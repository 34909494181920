import { format } from 'date-fns'
import {
  Box,
  Checkbox,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { bindMenu, bindTrigger } from 'material-ui-popup-state/es'
import { SearchOutlined } from '@material-ui/icons'

const useStyles = makeStyles({
  container: {
    padding: '0 0.5rem',
    display: 'flex',
  },
  columnHeadCell: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
})

interface SearchFilterProps {
  options: string[]
  disabled: boolean
  onUpdate: (data: string[]) => void
  value: string[]
  column: Column
  formatDate?: string
}

interface Column {
  id:
    | 'index'
    | 'name'
    | 'createdAt'
    | 'expireDate'
    | 'preview'
    | 'actions'
    | 'createdBy'
  label: string
  minWidth?: number
  filterable?: boolean
  formatDate?: string
}

export const SearchFilter: React.FC<SearchFilterProps> = ({
  options,
  onUpdate,
  disabled,
  formatDate,
  value,
  column,
}) => {
  const styles = useStyles()
  const [search, setSearch] = useState('')
  const getValue = () => {
    const formattedArray = value.map((v) =>
      formatDate ? format(new Date(v), formatDate) : v
    )
    return formattedArray.length > 2
      ? formattedArray.length + ' options'
      : formattedArray.join(', ')
  }
  const filteredOptions = options.filter((o) =>
    o?.toLowerCase().includes(search.toLowerCase())
  )

  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  return (
    <>
      <TextField
        style={{ color: 'black' }}
        className={styles.columnHeadCell}
        label={column.label}
        id={column.id}
        {...(!disabled
          ? {
              ...bindTrigger(popupState),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="start">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="filter"
                      className="svg-inline--fa fa-filter fa-w-16"
                      role="img"
                      style={{
                        color: 'rgba(0, 0, 0, 0.42)',
                        marginBottom: '10px',
                      }}
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"
                      ></path>
                    </svg>
                  </InputAdornment>
                ),
              },
            }
          : {})}
        disabled={disabled}
        value={getValue()}
      />
      <Menu
        {...bindMenu(popupState)}
        disableAutoFocus
        disableAutoFocusItem
        onKeyDown={(e) => {
          if (e.code === 'Backspace')
            setSearch(search.substr(0, search.length - 1))
        }}
        onKeyPress={(e) => {
          if (e.key === 'Delete') return
          if (e.key === 'Enter') return
          setSearch(search + e.key)
        }}
      >
        <Box className={styles.container}>
          <TextField
            placeholder="Search"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            className={styles.container}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoFocus={false}
            style={{ width: '100%' }}
            value={search}
          />
        </Box>
        <MenuItem
          onClick={() =>
            onUpdate(
              value?.length === filteredOptions.length ? [] : filteredOptions
            )
          }
        >
          <Checkbox
            checked={value?.length === filteredOptions?.length}
            indeterminate={value?.length > 0}
          />
          {value?.length === filteredOptions?.length
            ? 'Deselect all'
            : 'Select all'}
        </MenuItem>
        {filteredOptions?.map((v) => (
          <MenuItem
            value={v}
            key={v}
            onClick={() =>
              value.indexOf(v) > -1
                ? onUpdate([...value.filter((val) => val !== v)])
                : onUpdate([...value, v])
            }
          >
            <Checkbox checked={value.indexOf(v) > -1} />
            {formatDate ? format(new Date(v), formatDate) : v}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

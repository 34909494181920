import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { Product } from '../types/vmdata'
import { colorPalette } from '../styleVariables'

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  container: {
    minHeight: '22vh',
    maxHeight: '22vh',
    background: colorPalette.white,
    overflow: 'auto',
    marginBottom: '2rem',
    borderTop: `2px solid ${colorPalette.greyLight}`,
    borderBottom: `2px solid ${colorPalette.greyLight}`,
  },
  rowBody: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000029',
    borderRadius: '0px 0px 2px 2px',
  },
  columnHeadCell: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  columnRowCell: {
    fontSize: '1.5rem',
    padding: '0.65rem 1.35rem',
  },
  img: {
    width: '4.55rem',
  },
})

interface SearchProductsTableProps {
  products: Product[]
}

export const SearchProductsTable: React.FC<SearchProductsTableProps> = ({
  products,
}) => {
  const styles = useStyles()

  return (
    <Paper className={styles.root}>
      <div className={styles.container}>
        <Table stickyHeader>
          <TableBody>
            {products.map((row: Product) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.upc}
                  className={styles.rowBody}
                >
                  <TableCell className={styles.columnRowCell}>
                    <img
                      src={row.thumbnailUrl}
                      alt={row.styleName}
                      className={styles.img}
                    />
                  </TableCell>
                  <TableCell className={styles.columnRowCell}>
                    {row.styleName}
                  </TableCell>
                  <TableCell className={styles.columnRowCell}>
                    {row.colorCode}
                  </TableCell>
                  <TableCell className={styles.columnRowCell}>
                    {row.code}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

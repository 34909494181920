import React from 'react'
import { BaseComponent } from '../components/core/BaseComponent'
import { PageComponent } from '../components/core/PageComponent'
import { Aside } from '../components/core/Aside'

export const ThankYouPage: React.FC = () => {
  return (
    <BaseComponent>
      <Aside />
      <PageComponent>
        <div>ThankYouPage</div>
      </PageComponent>
    </BaseComponent>
  )
}

import { Alert } from '@material-ui/lab'
import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Users } from '../UserList'

interface UsersTablePopupProps {
  setDeletedStatus: (value: boolean) => void
  setDeletedStatusPermanent: (value: boolean) => void
  setRowToDelete: (row: Users | null) => void
  setEditableStatus: (value: boolean) => void
  setRowToEdit: (row: Users) => void
  setRowToAdd: (row: Users) => void
  setisEdited: (value: boolean) => void
  setisAdded: (value: boolean) => void
  setCreateStatus: (value: boolean) => void
  deletedStatus: boolean
  isEdited: boolean
  isAdded: boolean
  rowToAdd: Users
  rowToDelete: Users | null
  rowToEdit: Users | null
  isChecked: boolean
  deletedStatusPermanent: boolean
  setisChecked: (value: boolean) => void
}

export const UserTablePopup: React.FC<UsersTablePopupProps> = ({
  setRowToAdd,
  setCreateStatus,
  setDeletedStatus,
  setDeletedStatusPermanent,
  setRowToDelete,
  setisChecked,
  rowToDelete,
  isChecked,
  deletedStatus,
  isEdited,
  setEditableStatus,
  setRowToEdit,
  isAdded,
  deletedStatusPermanent,
  setisEdited,
  rowToEdit,
  rowToAdd,
  setisAdded,
}) => {
  let nameToDelete = rowToDelete?.name || ''

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deletedStatus}
        autoHideDuration={6000}
        onClose={() => {
          setDeletedStatus(false)
          setRowToDelete(null)
        }}
      >
        <Alert severity="success">{`${nameToDelete} has been removed from ${rowToDelete?.group?.name}`}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={deletedStatusPermanent}
        autoHideDuration={6000}
        message={`${nameToDelete} has been deleted`}
        onClose={() => {
          setDeletedStatusPermanent(false)
          setRowToDelete(null)
          setisChecked(false)
        }}
      >
        <Alert severity="success">{`${nameToDelete} has been deleted`}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isEdited}
        autoHideDuration={6000}
        onClose={() => {
          setEditableStatus(false)
          setRowToEdit({
            id: '',
            username: '',
            surname: '',
            name: '',
            email: '',
            role: '',
            createdAt: '',
            groupId: '',
            createdById: '',
          })
          setisEdited(false)
        }}
      >
        <Alert severity="success">{`${rowToEdit?.name} has been updated`}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isAdded}
        autoHideDuration={6000}
        onClose={() => {
          setCreateStatus(false)
          setRowToAdd({
            id: '',
            username: '',
            surname: '',
            name: '',
            email: '',
            role: '',
            createdAt: '',
            groupId: '',
            createdById: '',
          })
          setisAdded(false)
        }}
      >
        <Alert severity="success">{`${rowToAdd.name} has been added`}</Alert>
      </Snackbar>
    </>
  )
}

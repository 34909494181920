import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const style = createSlice({
  name: 'vm-bo/style',
  initialState,
  reducers: {
    setStyleData: (_, action: PayloadAction<string>) => {
      return action.payload
    },
  },
})

export const { setStyleData } = style.actions
export default style.reducer

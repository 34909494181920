import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Link } from '../../types/vmdata'

const initialState: Link[] = []

const links = createSlice({
  name: 'vm-bo/links',
  initialState,
  reducers: {
    addLink: (state, action: PayloadAction<Link>) => {
      const link = state.find((link) => link.url === action.payload.url)
      return !link ? [...state, action.payload] : state
    },
    removeLink: (state, action: PayloadAction<Link>) => {
      return state.filter((link) => link.url !== action.payload.url)
    },
    replaceLinks: (_state, action: PayloadAction<Link[] | undefined>) =>
      action.payload,
  },
})

export const { addLink, removeLink, replaceLinks } = links.actions
export default links.reducer

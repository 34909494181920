import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { IntroPage } from '../pages/IntroPage'
import { LoadPage } from '../pages/LoadPage'
import { EditPage } from '../pages/EditPage'
import { ThankYouPage } from '../pages/ThankYouPage'
import { LoginPage } from '../pages/LoginPage'
import { LogoutPage } from '../pages/LogoutPage'
import GroupListPage from '../pages/GroupListPage'
import GroupPage from '../pages/GroupPage'
import UserListPage from '../pages/UserListPage'

export const AppRoutes = {
  login: (): string => '/',
  intro: (): string => '/intro',
  logout: (): string => '/logout',
  create: (): string => '/create',
  edit: (): string => '/edit',
  load: (): string => '/load',
  editStyle: (): string => '/edit/style',
  editCatalog: (): string => '/edit/catalog',
  editProducts: (): string => '/edit/products',
  editConfirmation: (): string => '/edit/confirmation',
  thankYou: (): string => '/edit/thank-you',
  groupList: (): string => '/groups',
  userList: (): string => '/users',
  groupPage: (): string => '/groups/:id',
}

export const Routes: React.FC = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Switch>
        <Route exact path={AppRoutes.login()} component={LoginPage} />
        <PrivateRoute exact path={AppRoutes.intro()} component={IntroPage} />
        <PrivateRoute exact path={AppRoutes.logout()} component={LogoutPage} />
        <PrivateRoute exact path={AppRoutes.load()} component={LoadPage} />
        <PrivateRoute path={AppRoutes.edit()} component={EditPage} />
        <PrivateRoute
          path={AppRoutes.create()}
          component={() => <EditPage isNewProduct />}
        />
        <PrivateRoute
          exact
          path={AppRoutes.thankYou()}
          component={ThankYouPage}
        />
        <PrivateRoute
          exact
          path={AppRoutes.groupList()}
          component={GroupListPage}
        />
        <PrivateRoute
          exact
          path={AppRoutes.userList()}
          component={UserListPage}
        />
        <PrivateRoute
          exact
          path={AppRoutes.groupPage()}
          component={GroupPage}
        />
      </Switch>
    </BrowserRouter>
  )
}

const PrivateRoute = (props: any) => {
  const { component, ...rest } = props
  const Component = props.component
  return (
    <Route
      {...rest}
      render={() => {
        const userToken = localStorage.getItem('userToken')
        return userToken ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

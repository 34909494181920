import React, { useCallback, useEffect, useState } from 'react'
import { Box, Paper, TextField, Typography } from '@material-ui/core'
import { Link } from '../types/vmdata'

import { makeStyles } from '@material-ui/core/styles'
import { useRefValue } from '../hooks/useRefValue'
import { isValidUrl } from '../libs/utils'

const useStyles = makeStyles({
  linkForm: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.333rem',
    gap: '1rem',
    marginBottom: '2.5rem',
  },
  linkFormControl: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
  },
  linkFormLabel: {
    fontSize: '1.35rem',
    minWidth: '80px',
    fontWeight: 'bold',
  },
})

interface Props {
  data?: Link
  isDisabled: boolean
  isRequired?: boolean
  onChange: (data: Link | undefined) => void
  onValidated?: (valid: boolean) => void
}

export const LinkForm: React.FC<Props> = ({
  data,
  isDisabled,
  onChange,
  onValidated,
  isRequired = true,
}) => {
  const styles = useStyles()

  const [label, setLabel] = useState(data?.label || '')
  const [url, setURL] = useState(data?.url || '')
  const [validation, setValidation] = useState({
    label: false,
    url: false,
  })

  const onChangeRef = useRefValue(onChange)

  const validateForm = useCallback(
    (label: string, url: string) => {
      const isLabelValid =
        label.length > 0 || (!isRequired && label.length === 0)
      const isUrlValid = isValidUrl(url) || (!isRequired && url.length === 0)

      const labelNotValid =
        !isLabelValid || (!isRequired && url.length > 0 && label.length === 0)
      const urlNotValid =
        !isUrlValid || (!isRequired && label.length > 0 && !isValidUrl(url))

      setValidation({
        label: labelNotValid,
        url: urlNotValid,
      })

      return !labelNotValid && !urlNotValid
    },
    [isRequired]
  )

  useEffect(() => {
    const debounce = setTimeout(() => {
      onChangeRef.current(
        validateForm(label.trim(), url.trim())
          ? {
              label: label.trim(),
              url: url.trim(),
            }
          : undefined
      )
    }, 100)

    return () => clearTimeout(debounce)
  }, [label, onChangeRef, url, validateForm])

  useEffect(() => {
    !!onValidated && onValidated(!validation.label && !validation.url)
  }, [onValidated, validation.label, validation.url])

  return (
    <Paper className={styles.linkForm}>
      <Box className={styles.linkFormControl}>
        <Typography
          className={styles.linkFormLabel}
          component="label"
          htmlFor="linkLabel"
        >
          CTA Label
        </Typography>
        <TextField
          id="linkLabel"
          variant="outlined"
          size="small"
          defaultValue={label}
          inputProps={{ maxLength: 24 }}
          error={validation.label}
          onChange={(e) => setLabel(e.target.value)}
          disabled={isDisabled}
          fullWidth
        />
      </Box>
      <Box className={styles.linkFormControl}>
        <Typography
          className={styles.linkFormLabel}
          component="label"
          htmlFor="linkURL"
        >
          URL
        </Typography>
        <TextField
          id="linkURL"
          variant="outlined"
          size="small"
          defaultValue={url}
          error={validation.url}
          onChange={(e) => setURL(e.target.value)}
          disabled={isDisabled}
          placeholder="http(s)://..."
          fullWidth
        />
      </Box>
    </Paper>
  )
}

import { Upcs } from '../services/vtoCatalogServices'
import { Product } from '../types/vmdata'
import { getAssetImage, getBrandLogoFileName } from './utils'
import { imageThumbnailSuffix } from '../app/theme'

export const formatProduct = (products: Upcs[]): Product[] => {
  return products.map((product) => ({
    upc: product.upc.id,
    code: product.model.code,
    name:
      product.model.code && product.color.code
        ? `${product.model.code} ${product.color.code}`
        : '',
    colorCode: product.color.code || '',
    thumbnailUrl: `${product?.image?.angleUrl}${imageThumbnailSuffix}`,
    brand: {
      name: product.brand.name,
      logoUrl: getAssetImage(
        `brands/${getBrandLogoFileName(product?.brand?.name)}.svg`
      ),
    },
    lensColorLabel: product.lens.color || '',
    frameColorLabel: product.frame.color || '',
    styleName: product.style.name || '',
    size: product.sizing.lensBridgeSize || '',
    pictures: product.pictures,
    transitionsSupported: product.transitionsSupported,
  }))
}

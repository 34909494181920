import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'
import { colorPalette } from '../../styleVariables'

const useStyles = makeStyles(() =>
  createStyles({
    svg: {
      fill: colorPalette.blue,
      width: '1.85rem',
      height: '1.85rem',
    },
  })
)

interface ActionsProps {
  remove?: () => void
  edit?: () => void
}

export const Actions: React.FC<ActionsProps> = ({ remove, edit }) => {
  const styles = useStyles()
  return (
    <>
      {edit && (
        <IconButton onClick={edit}>
          <Edit className={styles.svg} />
        </IconButton>
      )}
      {remove && (
        <IconButton onClick={remove}>
          <Delete className={styles.svg} />
        </IconButton>
      )}
    </>
  )
}

import {
  Dialog,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Users } from './UserList'
import { colorPalette } from '../styleVariables'

const useStyles = makeStyles({
  content: {
    padding: '3rem',
    position: 'relative',
    minWidth: '40vw',
  },
  messageWrapper: {
    margin: '3rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  message: {
    fontSize: '1.35rem',
    color: colorPalette.greyLight,
  },
  formControl: {
    fontSize: '1.35rem',
    color: colorPalette.greyLight,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonText: {
    fontSize: '1.35rem',
    color: colorPalette.blue,
  },
  buttonTextRed: {
    fontSize: '1.35rem',
    color: 'red',
  },
})

interface DeleteUserDialogProps {
  isOpenPopup: boolean
  onClosePopup: () => void
  isChecked: boolean
  rowToDelete: Users | null
  setisChecked: (value: boolean) => void
  handleDelete: () => void
  handleDeleteFromGroup: () => void
}

export const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({
  isOpenPopup,
  onClosePopup,
  isChecked,
  rowToDelete,
  setisChecked,
  handleDelete,
  handleDeleteFromGroup,
}) => {
  const styles = useStyles()
  return (
    <>
      <Dialog open={isOpenPopup} onClose={onClosePopup} maxWidth="lg">
        <Box className={styles.content}>
          <Box className={styles.messageWrapper}>
            {isChecked ? (
              <Typography className={styles.message}>
                Are you sure you want to permanently delete{' '}
                <span className={styles.buttonText}>
                  {rowToDelete?.name} {rowToDelete?.surname}
                </span>
                ?
              </Typography>
            ) : (
              <Typography className={styles.message}>
                Are you sure you want to delete{' '}
                <span className={styles.buttonText}>
                  {rowToDelete?.name} {rowToDelete?.surname}
                </span>{' '}
                from{' '}
                <span className={styles.buttonText}>
                  {' '}
                  {rowToDelete?.group?.name}
                </span>
                ?
              </Typography>
            )}
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                color="primary"
                onClick={() => setisChecked(!isChecked)}
              />
            }
            label="Permanently delete user"
            className={styles.formControl}
          />
          <Box className={styles.buttonsWrapper}>
            <Button onClick={onClosePopup}>
              <Typography className={styles.buttonText}>cancel</Typography>
            </Button>
            {isChecked ? (
              <Button onClick={handleDelete}>
                <Typography className={styles.buttonTextRed}>
                  delete user
                </Typography>
              </Button>
            ) : (
              <Button onClick={handleDeleteFromGroup}>
                <Typography className={styles.buttonTextRed}>
                  remove user
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

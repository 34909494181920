import * as React from 'react'
import { Container } from '@material-ui/core'
import { colorPalette } from '../../styleVariables'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 'none',
      height: '100%',
      display: 'flex',
      color: colorPalette.black,
    },
  })
)

export const BaseComponent: React.FC = ({ children }) => {
  const styles = useStyles()

  return (
    <Container disableGutters className={styles.container}>
      <>{children}</>
    </Container>
  )
}

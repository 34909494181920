import React, { FC, useEffect } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { Aside } from '../components/core/Aside'
import { BaseComponent } from '../components/core/BaseComponent'
import { EditConfirmationPage } from './EditConfirmationPage'
import { EditExperienceInfoPage } from './EditExperienceInfoPage'
import { EditExpirationPage } from './EditExpirationPage'
import { EditLinksPage } from './EditLinksPage'
import { EditProductsPage } from './EditProductsPage'
import { PageComponent } from '../components/core/PageComponent'
import { WizardStepperHeader } from '../components/WizardStepperHeader'
import { VisualizationPage } from './VisualizationModePage'

export const getWizardSteps = (): {
  step: string
  component: React.ReactNode
}[] => {
  return [
    { step: 'experience info', component: <EditExperienceInfoPage /> },
    { step: 'expiration', component: <EditExpirationPage /> },
    { step: 'links', component: <EditLinksPage /> },
    { step: 'visualization', component: <VisualizationPage /> },
    { step: 'products', component: <EditProductsPage /> },
    { step: 'deploy', component: <EditConfirmationPage /> },
  ]
}

const steps = getWizardSteps()

interface PropsWizardStep {
  step: string
  isNewProduct?: boolean
}

const WizardStep: FC<PropsWizardStep> = ({ step, isNewProduct }) => {
  switch (step) {
    case 'experience info':
      return <EditExperienceInfoPage />
    case 'expiration':
      return <EditExpirationPage />
    case 'visualization':
      return <VisualizationPage />
    case 'products':
      return <EditProductsPage isNewProduct={isNewProduct} />
    case 'links':
      return <EditLinksPage />
    case 'deploy':
      return <EditConfirmationPage />
    default:
      return <EditExperienceInfoPage />
  }
}

interface Props {
  isNewProduct?: boolean
}

const StepContent: FC<Props> = ({ isNewProduct }) => {
  const match = useRouteMatch<{ step: string }>()
  const step = match.params.step

  return (
    <>
      <WizardStepperHeader />
      <WizardStep step={step} isNewProduct={isNewProduct} />
    </>
  )
}

interface Props {
  isNewProduct?: boolean
}

export const EditPage: React.FC<Props> = ({ isNewProduct }) => {
  const { path } = useRouteMatch()
  const match = useRouteMatch<{ step: string }>()
  const history = useHistory()
  const step = match.params.step

  useEffect(() => {
    if (!step) {
      const targetStep = steps[0].step
      history.replace(`${path}/${targetStep}`)
    }
  }, [step, history, path])

  return (
    <BaseComponent>
      <Aside userMustConfirmBack={true} />
      <PageComponent>
        <Switch>
          <Route path={`${path}/:step`}>
            <StepContent isNewProduct={isNewProduct} />
          </Route>
        </Switch>
      </PageComponent>
    </BaseComponent>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const expire = createSlice({
  name: 'vm-bo/expire',
  initialState,
  reducers: {
    setExpirationData: (_, action: PayloadAction<string | undefined>) => {
      return action.payload || ''
    },
  },
})

export const { setExpirationData } = expire.actions
export default expire.reducer

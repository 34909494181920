import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import vtoCatalogServices from '../../services/vtoCatalogServices'
import { VMListData } from '../../types/vmlistingdata'

interface Listing {
  listingData?: VMListData[]
  loading: boolean
  search: string | null
  error: string | null
  edittableUuid: string | null
}

const initialState: Listing = {
  listingData: [],
  loading: false,
  search: null,
  error: null,
  edittableUuid: null,
}

export const getListingData = createAsyncThunk(
  'vm-bo/listing/getListing',
  (onUnauthorized: () => void) =>
    vtoCatalogServices.getListingData(onUnauthorized)
)

export const deleteListingData = createAsyncThunk(
  'vm-bo/listing/deleteListing',
  async (data: VMListData) => vtoCatalogServices.deleteListingData(data)
)

const listing = createSlice({
  name: 'vm-bo/listing',
  initialState,
  reducers: {
    setEditableUuid: (state, action: PayloadAction<string>) => {
      state.edittableUuid = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
  },
  extraReducers: {
    [getListingData.pending.type]: (state) => {
      state.loading = true
    },
    [getListingData.fulfilled.type]: (
      state,
      action: PayloadAction<VMListData[]>
    ) => {
      state.listingData = action.payload
      state.loading = false
    },
    [getListingData.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.error = action.payload
    },

    [deleteListingData.pending.type]: (state) => {
      state.loading = true
    },
    [deleteListingData.fulfilled.type]: (state) => {
      state.loading = false
    },
    [deleteListingData.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { setEditableUuid, setSearch } = listing.actions
export default listing.reducer

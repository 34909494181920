import { responsiveFontSizes } from '@material-ui/core/styles'
// XXX: see - https://stackoverflow.com/a/64135466
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'

const defaulTheme = createMuiTheme()

const palette = {
  primary: { main: '#005192', dark: '#005192', light: '#9E9E9E' },
  secondary: { main: '#9E9E9E' },
}

const typography = {
  fontFamily: ['Roboto', 'serif'].join(','),
  fontSize: 14,
}

const overrides: Overrides = {
  MuiInputBase: {
    root: {
      fontSize: '1.35rem',
    },
    // Remove browsers style for input autocomplete
    input: {
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        '-webkit-box-shadow': `0 0 0 30px ${defaulTheme.palette.grey[50]} inset !important`,
        '-webkit-text-fill-color': 'currentColor !important',
      },
    },
  },
  MuiTypography: {
    body2: {
      fontSize: '1.175rem',
    },
  },
  MuiStepIcon: {
    root: {
      width: '2rem',
      height: '2rem',
    },
    text: {
      fontSize: '1.175rem',
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: '1.35rem',
    },
  },
  MuiButton: {
    label: {
      fontSize: '1.175rem',
    },
  },
}

export const theme = responsiveFontSizes(
  createMuiTheme({ palette, typography, overrides })
)

export const imageThumbnailSuffix = '?imWidth=100'

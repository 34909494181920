import {
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Input,
} from '@material-ui/core'
import { KeyboardArrowRight, Delete, Check } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useHistory } from 'react-router-dom'
import { colorPalette } from '../../styleVariables'
import { AppRoutes } from '../../app/routers'
import { Group } from '../ListingGroups'
import { Actions } from './Actions'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '70vh',
    minHeight: '70vh',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    overflow: 'auto',
    position: 'relative',
  },
  rowHead: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
  },
  rowBody: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000029',
    borderRadius: '0px 0px 2px 2px',
  },
  columnHeadCell: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  columnRowCell: {
    fontSize: '1.1rem',
    padding: '0.65rem 1.35rem',
  },
  tooltip: {
    color: ' #fff',
    padding: ' 4px    8px',
    fontsize: ' 0.625rem',
    maxWidth: ' 300px',
    wordwrap: ' break-word',
    fontFamily: ' Roboto,serif',
    fontWeight: 500,
    lineHeight: ' 1.4em',
    borderRadius: '8px',
    backgroundColor: 'rgba(97, 97, 97, 1)',
  },
  img: {
    width: '4.55rem',
  },
  svg: {
    fill: colorPalette.blue,
    width: '1.85rem',
    height: '1.85rem',
  },
  redSvg: {
    fill: 'red',
    width: '1.85rem',
    height: '1.85rem',
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
  textInput: {
    paddingLeft: '1.2rem',
  },
  content: {
    padding: '3rem',
    position: 'relative',
    minWidth: '40vw',
  },
  title: {
    fontSize: '2.35rem',
    fontWeight: 700,
  },
  messageWrapper: {
    margin: '3rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

interface GroupsTableBodyProps {
  setEditableStatus: (value: boolean) => void
  setRowToEdit: (row: Group) => void
  setCreateStatus: (value: boolean) => void
  rowToAdd: Group
  rowToEdit: Group
  createStatus: boolean
  editableStatus: boolean
  handleAddGroup: () => void
  handleEdit: (row: Group) => void
  onOpenPopup: (row: Group) => void
  handleAddGroupName: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => void
  handleChangeGroupName: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => void
  searchValue: string
  groups: Group[] | undefined
}

export const GroupsTableBody: React.FC<GroupsTableBodyProps> = ({
  setEditableStatus,
  setRowToEdit,
  setCreateStatus,
  handleEdit,
  onOpenPopup,
  rowToAdd,
  createStatus,
  editableStatus,
  handleAddGroup,
  handleAddGroupName,
  handleChangeGroupName,
  searchValue,
  groups,
  rowToEdit,
}) => {
  const styles = useStyles()
  const history = useHistory()
  return (
    <>
      <TableBody>
        {createStatus && (
          <TableRow role="checkbox" tabIndex={-1} className={styles.rowBody}>
            <TableCell className={styles.columnRowCell} width={'10%'}>
              {!createStatus && (
                <IconButton
                  onClick={() => history.push(`${AppRoutes.groupPage()}`)}
                >
                  <KeyboardArrowRight className={styles.svg} />
                </IconButton>
              )}
            </TableCell>
            <TableCell className={styles.columnRowCell} width={'75%'}>
              <Input
                className={styles.textInput}
                value={rowToAdd.name}
                onChange={(ev) => handleAddGroupName(ev, 'name')}
              />
            </TableCell>
            <TableCell className={styles.columnRowCell}>
              <IconButton onClick={() => setCreateStatus(false)}>
                <Delete className={styles.redSvg} />
              </IconButton>
              <IconButton onClick={handleAddGroup}>
                <Check className={styles.svg} />
              </IconButton>
            </TableCell>
          </TableRow>
        )}
        {groups
          ?.filter((row) =>
            row.name?.toLowerCase().includes(searchValue?.toLowerCase())
          )
          .map((row, ind) => (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={ind}
              className={styles.rowBody}
            >
              <TableCell className={styles.columnRowCell} width={'10%'}>
                <Link to={`/groups/${row.id}`}>
                  <IconButton>
                    <KeyboardArrowRight className={styles.svg} />
                  </IconButton>
                </Link>
              </TableCell>
              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell} width={'75%'}>
                  {row.name}
                </TableCell>
              ) : (
                <TableCell padding="none">
                  <Input
                    onChange={(ev) => handleChangeGroupName(ev, 'name')}
                    value={rowToEdit.name}
                  />
                </TableCell>
              )}
              {!(row.id === rowToEdit?.id && editableStatus) ? (
                <TableCell className={styles.columnRowCell}>
                  <Actions
                    remove={() => onOpenPopup(row)}
                    edit={() => {
                      setRowToEdit(row)
                      setEditableStatus(true)
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell className={styles.columnRowCell}>
                  <IconButton onClick={() => setEditableStatus(false)}>
                    <Delete className={styles.redSvg} />
                  </IconButton>
                  <IconButton onClick={() => handleEdit(row)}>
                    <Check className={styles.svg} />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </>
  )
}

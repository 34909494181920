import React from 'react'

export const Logo: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      width="100%"
      height="100%"
      fill="#fff"
      viewBox="0 0 231.88 187.63"
    >
      <path d="M76.72,63.51A20.51,20.51,0,0,0,97.21,84h11V80h-11A16.51,16.51,0,0,1,80.72,63.51v-31l-4-6.52Z" />
      <path d="M82.52,6.23,84.7,9.78A16.46,16.46,0,0,1,97.21,4h11V0h-11A20.46,20.46,0,0,0,82.52,6.23Z" />
      <path d="M0,111.08H5.54l7.37,20.43,7.6-20.43H25.7l-10.92,27H10.62Z" />
      <path d="M27.15,113.56a2.63,2.63,0,0,1,.82-1.93,2.81,2.81,0,0,1,2.08-.82,3,3,0,0,1,2.12.78,2.69,2.69,0,0,1,0,3.94,3,3,0,0,1-2.12.78,2.81,2.81,0,0,1-2.08-.82A2.63,2.63,0,0,1,27.15,113.56Zm.65,6.22h4.58v18.33H27.8Z" />
      <path d="M36.81,119.78h4.58v2.91h.08a5.83,5.83,0,0,1,2.18-2.49,6,6,0,0,1,3.24-.87,4.39,4.39,0,0,1,.82.07c.27,0,.55.12.83.19V124c-.39-.1-.76-.18-1.13-.25a6.77,6.77,0,0,0-1.09-.09,5,5,0,0,0-2.58.59,4.82,4.82,0,0,0-1.49,1.36,4.43,4.43,0,0,0-.68,1.52,5.5,5.5,0,0,0-.18,1.15v9.81H36.81Z" />
      <path d="M50.22,123.68v-3.9H54v-5.3h4.58v5.3h5v3.9h-5v8a4,4,0,0,0,.51,2.17,2.26,2.26,0,0,0,2,.81,7.35,7.35,0,0,0,1.33-.14,2.71,2.71,0,0,0,1.15-.44V138a5.89,5.89,0,0,1-1.78.48,14.54,14.54,0,0,1-1.85.13,9.34,9.34,0,0,1-3-.4A4,4,0,0,1,55.16,137a4.79,4.79,0,0,1-.92-2,14.87,14.87,0,0,1-.24-2.83v-8.4Z" />
      <path d="M83.09,138.11H78.74v-2.94h-.07a6.47,6.47,0,0,1-2,2.39,5.9,5.9,0,0,1-3.63,1,7.44,7.44,0,0,1-3.11-.59,5.68,5.68,0,0,1-2.08-1.6,6.27,6.27,0,0,1-1.15-2.31,10.56,10.56,0,0,1-.34-2.72V119.78H71v9.28c0,.51,0,1.08.08,1.7a6,6,0,0,0,.4,1.76,3.32,3.32,0,0,0,1,1.37,2.9,2.9,0,0,0,1.89.56,4.42,4.42,0,0,0,1.93-.38,3.79,3.79,0,0,0,1.3-1,4,4,0,0,0,.74-1.49,6.27,6.27,0,0,0,.23-1.75v-10h4.58Z" />
      <path d="M98.9,135.59h-.11a5.92,5.92,0,0,1-2.52,2.28,7.93,7.93,0,0,1-3.36.7,8.85,8.85,0,0,1-2.46-.34,6.54,6.54,0,0,1-2.14-1,5.21,5.21,0,0,1-1.51-1.72,4.91,4.91,0,0,1-.57-2.41,5.51,5.51,0,0,1,.53-2.54,5.2,5.2,0,0,1,1.43-1.75,7.29,7.29,0,0,1,2.08-1.13,14.19,14.19,0,0,1,2.47-.63,22.17,22.17,0,0,1,2.57-.27c.87,0,1.68,0,2.45,0H98.9v-.5a3,3,0,0,0-1.18-2.58,5,5,0,0,0-3-.86,7,7,0,0,0-2.71.52,6.85,6.85,0,0,0-2.17,1.39l-2.41-2.4A8.87,8.87,0,0,1,91,120a13.09,13.09,0,0,1,4.22-.68,10.69,10.69,0,0,1,3.28.43,7.33,7.33,0,0,1,2.22,1.13,4.94,4.94,0,0,1,1.35,1.59,7.16,7.16,0,0,1,.69,1.81,10.3,10.3,0,0,1,.25,1.78c0,.57,0,1.07,0,1.5v10.54H98.9Zm-.26-5.69h-1c-.63,0-1.35,0-2.14.08a11.17,11.17,0,0,0-2.23.36,5.15,5.15,0,0,0-1.78.84,1.85,1.85,0,0,0-.72,1.55,2,2,0,0,0,.28,1.09,2.64,2.64,0,0,0,.75.74,3.06,3.06,0,0,0,1,.44,5,5,0,0,0,1.15.13A4.77,4.77,0,0,0,97.51,134a4.17,4.17,0,0,0,1.13-3.08Z" />
      <path d="M107.19,109.24h4.58v28.87h-4.58Z" />
      <path d="M127.2,111.08h7.33L141.75,130,149,111.08h7.26v27h-4.58V115.66h-.08l-8.21,22.45h-3.36l-8.21-22.45h-.08v22.45H127.2Z" />
      <path d="M160.61,113.56a2.67,2.67,0,0,1,.82-1.93,2.83,2.83,0,0,1,2.09-.82,3.05,3.05,0,0,1,2.12.78,2.71,2.71,0,0,1,0,3.94,3.05,3.05,0,0,1-2.12.78,2.83,2.83,0,0,1-2.09-.82A2.67,2.67,0,0,1,160.61,113.56Zm.65,6.22h4.59v18.33h-4.59Z" />
      <path d="M170.27,119.78h4.59v2.91h.07a5.83,5.83,0,0,1,2.18-2.49,6.05,6.05,0,0,1,3.25-.87,4.47,4.47,0,0,1,.82.07c.26,0,.54.12.82.19V124c-.38-.1-.76-.18-1.13-.25a6.77,6.77,0,0,0-1.09-.09,4.91,4.91,0,0,0-2.57.59,4.71,4.71,0,0,0-1.49,1.36,4.28,4.28,0,0,0-.69,1.52,5.34,5.34,0,0,0-.17,1.15v9.81h-4.59Z" />
      <path d="M184.29,119.78h4.58v2.91H189a5.76,5.76,0,0,1,2.18-2.49,6,6,0,0,1,3.24-.87,4.39,4.39,0,0,1,.82.07c.27,0,.54.12.82.19V124c-.38-.1-.75-.18-1.12-.25a6.87,6.87,0,0,0-1.09-.09,5,5,0,0,0-2.58.59,4.59,4.59,0,0,0-2.18,2.88,6.07,6.07,0,0,0-.17,1.15v9.81h-4.58Z" />
      <path d="M196.7,129a9.7,9.7,0,0,1,.78-3.95,9.18,9.18,0,0,1,2.12-3,9.39,9.39,0,0,1,3.17-1.95,11.5,11.5,0,0,1,7.79,0,9.39,9.39,0,0,1,3.17,1.95,9.18,9.18,0,0,1,2.12,3,10.39,10.39,0,0,1,0,7.9,9.06,9.06,0,0,1-5.29,5,11.34,11.34,0,0,1-7.79,0,9.06,9.06,0,0,1-5.29-5A9.7,9.7,0,0,1,196.7,129Zm4.58,0a5.78,5.78,0,0,0,.37,2.06,5.18,5.18,0,0,0,1.06,1.76,5.25,5.25,0,0,0,4,1.68,5.25,5.25,0,0,0,4-1.68,5.2,5.2,0,0,0,1.07-1.76,6.07,6.07,0,0,0,0-4.12,5.11,5.11,0,0,0-1.07-1.76,5.25,5.25,0,0,0-4-1.68,5.25,5.25,0,0,0-4,1.68,5.09,5.09,0,0,0-1.06,1.76A5.78,5.78,0,0,0,201.28,129Z" />
      <path d="M220.07,119.78h4.58v2.91h.08a5.76,5.76,0,0,1,2.18-2.49,6,6,0,0,1,3.24-.87,4.39,4.39,0,0,1,.82.07c.27,0,.54.12.82.19V124c-.38-.1-.75-.18-1.12-.25a6.77,6.77,0,0,0-1.09-.09,5,5,0,0,0-2.58.59,4.59,4.59,0,0,0-2.18,2.88,6.07,6.07,0,0,0-.17,1.15v9.81h-4.58Z" />
      <path d="M6.11,186.54a7.69,7.69,0,0,1-3.15-3,8.49,8.49,0,0,1-1.13-4.39V168.65A8.53,8.53,0,0,1,3,164.25a7.67,7.67,0,0,1,3.15-2.95,10,10,0,0,1,4.69-1.05,10.2,10.2,0,0,1,4.68,1,7.46,7.46,0,0,1,3.15,2.88,8,8,0,0,1,1.11,4.18v.5a.34.34,0,0,1-.38.38H17.41a.34.34,0,0,1-.38-.38v-.46a5.46,5.46,0,0,0-1.7-4.16,6.32,6.32,0,0,0-4.53-1.61,6.31,6.31,0,0,0-4.54,1.64,5.7,5.7,0,0,0-1.72,4.32v10.69a5.66,5.66,0,0,0,1.76,4.32,6.51,6.51,0,0,0,4.62,1.64,6.31,6.31,0,0,0,4.45-1.53A5.3,5.3,0,0,0,17,179.61v-3.48a.13.13,0,0,0-.15-.15H11.26a.34.34,0,0,1-.38-.38V174a.34.34,0,0,1,.38-.38h8.1a.34.34,0,0,1,.38.38v4.93a8.36,8.36,0,0,1-2.43,6.38,9.1,9.1,0,0,1-6.51,2.29A10,10,0,0,1,6.11,186.54Z" />
      <path d="M49,177.05v1.22a.33.33,0,0,1-.38.38H36.24c-.11,0-.16,0-.16.15a17.18,17.18,0,0,0,.23,2.72,4.57,4.57,0,0,0,1.8,2.71,5.83,5.83,0,0,0,3.51,1,5.4,5.4,0,0,0,2.83-.73,5,5,0,0,0,1.91-2.06c.15-.23.33-.28.53-.15l1.49.88a.38.38,0,0,1,.15.53,6.81,6.81,0,0,1-2.86,2.89,9,9,0,0,1-4.39,1,8.09,8.09,0,0,1-4.55-1.3,6.92,6.92,0,0,1-2.63-3.52,15.59,15.59,0,0,1-.69-5.27c0-1.06,0-1.94.06-2.61a8.7,8.7,0,0,1,.28-1.82,7.16,7.16,0,0,1,2.62-4,7.67,7.67,0,0,1,4.75-1.49,7.53,7.53,0,0,1,5.35,1.76,8.16,8.16,0,0,1,2.33,5A19.69,19.69,0,0,1,49,177.05ZM38,171a4.44,4.44,0,0,0-1.62,2.61,12.48,12.48,0,0,0-.31,2.6c0,.1.05.15.16.15h9.89a.13.13,0,0,0,.15-.15,17.41,17.41,0,0,0-.23-2.45,4.88,4.88,0,0,0-1.7-2.73,5.07,5.07,0,0,0-3.23-1A4.9,4.9,0,0,0,38,171Z" />
      <path d="M75.7,169.49a6.45,6.45,0,0,1,1.82,4.81v12.6c0,.26-.13.38-.39.38H75.19a.33.33,0,0,1-.38-.38V174.83a4.76,4.76,0,0,0-1.28-3.43,4.4,4.4,0,0,0-3.31-1.34,4.45,4.45,0,0,0-4.69,4.7V186.9c0,.26-.13.38-.39.38H63.2c-.26,0-.39-.12-.39-.38V168.34a.34.34,0,0,1,.39-.38h1.94a.34.34,0,0,1,.39.38V170a.13.13,0,0,0,0,.12s.07,0,.1,0a5.89,5.89,0,0,1,5.15-2.37A6.61,6.61,0,0,1,75.7,169.49Z" />
      <path d="M106.58,177.05v1.22a.34.34,0,0,1-.38.38H93.86a.13.13,0,0,0-.15.15,16,16,0,0,0,.23,2.72,4.6,4.6,0,0,0,1.79,2.71,5.88,5.88,0,0,0,3.52,1,5.36,5.36,0,0,0,2.82-.73,5,5,0,0,0,1.91-2.06.36.36,0,0,1,.54-.15l1.48.88a.37.37,0,0,1,.16.53,6.84,6.84,0,0,1-2.87,2.89,8.94,8.94,0,0,1-4.39,1,8.05,8.05,0,0,1-4.54-1.3,7,7,0,0,1-2.64-3.52,15.63,15.63,0,0,1-.68-5.27c0-1.06,0-1.94,0-2.61a8.67,8.67,0,0,1,.29-1.82,7.09,7.09,0,0,1,2.61-4,7.7,7.7,0,0,1,4.76-1.49,7.55,7.55,0,0,1,5.35,1.76,8.15,8.15,0,0,1,2.32,5A18,18,0,0,1,106.58,177.05ZM95.64,171A4.51,4.51,0,0,0,94,173.61a12.64,12.64,0,0,0-.3,2.6.13.13,0,0,0,.15.15h9.89a.13.13,0,0,0,.15-.15,16,16,0,0,0-.23-2.45A4.86,4.86,0,0,0,102,171a5.09,5.09,0,0,0-3.23-1A4.86,4.86,0,0,0,95.64,171Z" />
      <path d="M130.41,168.26a.42.42,0,0,1,.23.5l-.42,1.87a.33.33,0,0,1-.5.23,3.85,3.85,0,0,0-1.49-.27l-.5,0a4.53,4.53,0,0,0-3.28,1.47,5,5,0,0,0-1.3,3.53V186.9a.33.33,0,0,1-.38.38h-2a.33.33,0,0,1-.38-.38V168.34a.34.34,0,0,1,.38-.38h2a.34.34,0,0,1,.38.38v2.33c0,.08,0,.12.06.13s.07,0,.09-.05a6.14,6.14,0,0,1,2-2.2,5.26,5.26,0,0,1,2.88-.78A4.53,4.53,0,0,1,130.41,168.26Z" />
      <path d="M154.73,169.41a6.07,6.07,0,0,1,1.87,4.66V186.9a.33.33,0,0,1-.38.38h-1.95a.33.33,0,0,1-.38-.38v-1.57a.13.13,0,0,0-.06-.11s-.08,0-.13,0a6.06,6.06,0,0,1-2.48,1.74,9.44,9.44,0,0,1-3.4.59,7.32,7.32,0,0,1-4.54-1.38,4.88,4.88,0,0,1-1.84-4.2,5.48,5.48,0,0,1,2.08-4.52,9,9,0,0,1,5.79-1.66h4.43c.1,0,.15-.05.15-.16v-1.45a4.21,4.21,0,0,0-1.09-3.07,4.5,4.5,0,0,0-3.38-1.13,5.64,5.64,0,0,0-3,.71,3,3,0,0,0-1.41,2,.41.41,0,0,1-.46.35l-2.06-.27c-.28-.05-.39-.15-.34-.3a4.92,4.92,0,0,1,2.29-3.48,8.7,8.7,0,0,1,5-1.34A7.46,7.46,0,0,1,154.73,169.41Zm-2.48,14.72a3.51,3.51,0,0,0,1.64-3v-3a.14.14,0,0,0-.15-.16h-4a7.11,7.11,0,0,0-4,1,3.25,3.25,0,0,0-1.53,2.86,3,3,0,0,0,1.17,2.54,5,5,0,0,0,3,.86A6.73,6.73,0,0,0,152.25,184.13Z" />
      <path d="M178.87,170.21h-4.32c-.1,0-.15,0-.15.16v11.26a3.37,3.37,0,0,0,.82,2.58,3.8,3.8,0,0,0,2.58.74h.95a.33.33,0,0,1,.38.38v1.57a.33.33,0,0,1-.38.38c-.3,0-.78,0-1.41,0a7,7,0,0,1-4.16-1c-1-.69-1.42-2-1.42-3.82v-12.1c0-.11,0-.16-.15-.16h-2.33a.34.34,0,0,1-.38-.38v-1.49a.34.34,0,0,1,.38-.38h2.33a.13.13,0,0,0,.15-.15v-4.43c0-.26.13-.38.39-.38H174a.33.33,0,0,1,.38.38v4.43a.13.13,0,0,0,.15.15h4.32a.34.34,0,0,1,.38.38v1.49A.34.34,0,0,1,178.87,170.21Z" />
      <path d="M195,186.17a7.21,7.21,0,0,1-2.75-3.89,16.51,16.51,0,0,1-.54-4.7,16.12,16.12,0,0,1,.54-4.66,7,7,0,0,1,2.71-3.85,8,8,0,0,1,4.81-1.42,7.75,7.75,0,0,1,4.68,1.42,7,7,0,0,1,2.69,3.82,20.68,20.68,0,0,1,0,9.39,7.06,7.06,0,0,1-2.69,3.89,8.57,8.57,0,0,1-9.45,0Zm7.87-2a5,5,0,0,0,1.75-2.73,16.66,16.66,0,0,0,.35-3.86,18.73,18.73,0,0,0-.31-3.86A5,5,0,0,0,202.9,171a5.08,5.08,0,0,0-3.18-1,4.86,4.86,0,0,0-4.93,3.74,24.19,24.19,0,0,0,0,7.72,4.78,4.78,0,0,0,1.74,2.73,5.14,5.14,0,0,0,3.22,1A5,5,0,0,0,202.89,184.21Z" />
      <path d="M231.64,168.26a.42.42,0,0,1,.23.5l-.42,1.87c-.08.26-.24.33-.5.23a3.85,3.85,0,0,0-1.49-.27l-.49,0a4.55,4.55,0,0,0-3.29,1.47,5,5,0,0,0-1.29,3.53V186.9c0,.26-.13.38-.39.38h-1.94c-.26,0-.39-.12-.39-.38V168.34a.34.34,0,0,1,.39-.38H224a.34.34,0,0,1,.39.38v2.33c0,.08,0,.12.05.13s.07,0,.1-.05a6,6,0,0,1,2-2.2,5.31,5.31,0,0,1,2.88-.78A4.53,4.53,0,0,1,231.64,168.26Z" />
      <path d="M119.39,81a2,2,0,0,1-1.69-.92l-47-73a2,2,0,0,1,3.37-2.16l45.32,70.39L164.7,4.92a2,2,0,0,1,3.37,2.16l-47,73A2,2,0,0,1,119.39,81Z" />
      <path d="M162.05,63.51A20.51,20.51,0,0,1,141.56,84h-11V80h11a16.51,16.51,0,0,0,16.49-16.49v-31l4-6.52Z" />
      <path d="M156.25,6.23l-2.18,3.55A16.44,16.44,0,0,0,141.56,4h-11V0h11A20.46,20.46,0,0,1,156.25,6.23Z" />
    </svg>
  )
}

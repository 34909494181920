import { usePrevious } from './usePrevious'
import { Product } from '../types/vmdata'
import { useEffect, useState } from 'react'

export const useProductListUpdate = (products: Product[]): Product[] => {
  const [productUpdate, setProductUpdate] = useState<Product[]>([])

  const productsPrev = usePrevious(products)

  useEffect(() => {
    const newProducts = products.filter(
      (product) => !productsPrev.some((p) => p.upc === product.upc)
    )
    if (newProducts.length > 0) {
      setProductUpdate(newProducts)
    }
  }, [products, productsPrev])

  useEffect(() => {
    if (productUpdate.length > 0) {
      const timer = setTimeout(() => {
        setProductUpdate([])
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [productUpdate])

  return productUpdate
}

import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { ExitToApp, SupervisedUserCircleRounded } from '@material-ui/icons'
import { AppRoutes } from '../app/routers'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectVmDataExperience } from '../features/vmdataSelectors'
import { selectUserName } from '../features/userSelectors'

const useStyles = makeStyles(() =>
  createStyles({
    nameContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    logoutContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    },
    userIcon: {
      marginRight: '1rem',
    },
    logoutIcon: {
      marginLeft: '1rem',
    },
    text: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  })
)

interface UserInfoProps {
  style?: any
}

export const UserInfo: React.FC<UserInfoProps> = ({ style }) => {
  const styles = useStyles()
  const history = useHistory()
  const userName = useSelector(selectUserName)

  const logout = () => {
    history.push(`${AppRoutes.logout()}`)
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      className={style}
    >
      <div className={styles.nameContainer}>
        <SupervisedUserCircleRounded
          className={styles.userIcon}
          fontSize="large"
        />
        <Typography className={styles.text}>{userName}</Typography>
      </div>
      <div className={styles.logoutContainer} onClick={logout}>
        <Typography className={styles.text}>Logout</Typography>
        <ExitToApp className={styles.logoutIcon} fontSize="large" />
      </div>
    </Box>
  )
}

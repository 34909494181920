import {
  Container,
  Box,
  Button,
  Typography,
  createStyles,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import { List, Add, Settings } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../app/routers'
import { Users } from '../components/UserList'
import { Logo } from '../components/Logo'
import { UserInfo } from '../components/UserInfo'
import UserServices from '../services/userServices'
import { colorPalette } from '../styleVariables'
import { Version } from '../components/Version'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: 'none',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage:
        'radial-gradient(farthest-corner at 50% 100% , #1C7CE0 0%, #150051 100%)',
      color: colorPalette.white,
      animation: 'filter-animation 6s infinite ease-in',
    },

    loaderWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage:
        'radial-gradient(farthest-corner at 50% 100% , #1C7CE0 0%, #150051 100%)',
      zIndex: 1,
    },
    btn: {
      padding: '1rem 5.8rem',
      fontSize: '1.125rem',
      color: colorPalette.white,
      '&:hover': {
        backgroundColor: 'rgba(213, 213, 213, 0.5)',
      },
    },
    btnList: {
      backgroundColor: 'transparent',
      border: `2px solid ${colorPalette.white}`,
      marginBottom: '2rem',
    },
    btnCreate: {
      backgroundColor: colorPalette.blue,
      marginBottom: '2rem',
    },
    btnLogout: {
      marginTop: '4rem',
      backgroundColor: colorPalette.blue,
    },
    btnText: {
      fontSize: '1.125rem',
      marginLeft: '1rem',
      fontWeight: 500,
    },
    btnLogoutText: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    logo: {
      width: '16.75rem',
    },
    userInfo: {
      marginTop: '8rem',
    },
    version: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      color: '#ffffff',
    },
  })
)

export const IntroPage = () => {
  const styles = useStyles()
  const history = useHistory()
  const [user, setUser] = useState<Users>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const user = await UserServices.user()
      setUser(user)
      setLoading(false)
    })()
  }, [])

  return (
    <Container className={styles.container} disableGutters>
      <Box m="5rem" className={styles.logo}>
        <Logo />
      </Box>
      {loading && (
        <Box className={styles.loaderWrapper}>
          <CircularProgress />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gridColumnGap="20px">
        <Button
          variant="contained"
          className={`${styles.btn} ${styles.btnList}`}
          onClick={() => history.push(`${AppRoutes.load()}`)}
        >
          <List fontSize="large" />
          <Typography className={styles.btnText}>List</Typography>
        </Button>
        <Button
          variant="contained"
          className={`${styles.btn} ${styles.btnCreate}`}
          onClick={() => history.push(`${AppRoutes.create()}`)}
        >
          <Add fontSize="large" />
          <Typography className={styles.btnText}>Create new</Typography>
        </Button>
        {user?.role === 'Admin' && (
          <Button
            variant="contained"
            className={`${styles.btn} ${styles.btnCreate}`}
            onClick={() => history.push(`${AppRoutes.groupList()}`)}
          >
            <Settings />
            <Typography className={styles.btnText}>Control Panel</Typography>
          </Button>
        )}
        <UserInfo style={styles.userInfo} />
      </Box>
      <Version className={styles.version} />
    </Container>
  )
}

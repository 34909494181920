/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import VTOCatalogServices, {
  ProductPayload,
  Upcs,
} from '../services/vtoCatalogServices'
import {
  addProduct,
  addProducts,
  updateProduct,
} from '../features/products/productSlice'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  appendImageSizeParams,
  getBrandLogoSrc,
  sanitazeString,
} from '../libs/utils'

import { Product } from '../types/vmdata'
import { Search } from '@material-ui/icons'
import { SearchProductsTable } from './SearchProductsTable'
import { colorPalette } from '../styleVariables'
import { formatProduct } from '../libs/formatProduct'
import { useAsync } from '../hooks/useAsync'
import { useDispatch } from 'react-redux'
import {
  getGen8,
  getGenS,
  getXtractive,
  getXtractivePolarized,
} from '../libs/transitionsLens'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: '3rem',
      position: 'relative',
    },
    title: {
      fontSize: '2.35rem',
      fontWeight: 700,
      marginBottom: '2rem',
    },
    small: {
      fontSize: '1rem',
    },
    searchWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '3rem',
    },
    searchInput: {
      marginRight: '2rem',
      minWidth: '25rem',
    },
    productPropsWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '4rem',
    },
    inputFirstRow: {
      flex: 1,
      '&:not(:last-child)': {
        marginRight: '2rem',
      },
    },
    inputSecondRow: {
      flex: 1,
      marginRight: '2rem',
    },
    imageWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    brandWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: '4rem',
    },
    image: {
      width: '7rem',
      height: '7rem',
      marginRight: '1rem',
      display: 'flex',
      position: 'relative',
      border: `2px solid ${colorPalette.grey}`,
      '& svg': {
        width: '100%',
        height: 'auto',
      },
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
      },
    },
    imageProduct: {
      width: '16rem',
      height: '10rem',
      marginRight: '1rem',
      display: 'flex',
      position: 'relative',
      border: `2px solid ${colorPalette.grey}`,
      '& svg': {
        width: '100%',
        height: 'auto',
      },
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
      },
    },
    insertLinkIcon: { marginRight: '5px' },
    btnsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btnText: {
      fontSize: '1.35rem',
    },
    switcherWrapper: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '5rem',
    },
    transitionsLabel: {
      fontSize: '1.35rem',
    },
    transitionsWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '4rem',
    },
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 1,
    },
    generateContainer: {
      marginLeft: '76px',
      marginBottom: '3rem',
      marginTop: '5px',
    },
    generateContainerLink: {
      display: 'flex',
      alignItems: 'center',
    },
    infoAppearenceLabel: {
      paddingBottom: '4rem',
      fontSize: '1.35rem',
      color: colorPalette.greyDark,
    },
    lensLabel: {
      fontSize: '1.35rem',
      color: colorPalette.greyDark,
    },
    select: {
      width: '100%',
      marginBottom: '5rem',
    },
    menuItem: {
      fontSize: '1.35rem',
      display: 'flex',
    },
    selectsContainer: {
      width: '100%',
      display: 'flex',
      gap: '20px',
    },
    separator: {
      paddingRight: '12px',
    },
  })
)

interface AddProductModalProps {
  isOpen: boolean
  onClose: () => void
  product?: Product | null
}

const defaultProduct: Product = {
  brand: {
    name: '',
    logoUrl: '',
  },
  code: '',
  colorCode: '',
  frameColorLabel: '',
  lensColorLabel: '',
  name: '',
  size: '',
  styleName: '',
  thumbnailUrl: '',
  upc: '',
  pictures: {},
  isTransition: false,
}

export const AddProductModal: React.FC<AddProductModalProps> = ({
  isOpen,
  onClose,
  product,
}) => {
  const styles = useStyles()
  const [upc, setUpc] = useState('')
  const [invalidProductError, setInvalidProductError] = useState(false)
  const [selectedIsMultipleSearch, setMultipleSearch] = useState(false)
  const isMultipleSearch = selectedIsMultipleSearch && !product
  const [products, setProducts] = useState<Product[]>([])
  const [currentProduct, setCurrentProduct] = useState<Product>(
    product || defaultProduct
  )

  const [loadingCatalogImage, setLoadingCatalogImage] = useState(false)
  const [upcNotFound, setUpcNotFound] = useState('')
  const [showBrandLogo, setShowBrandLogo] = useState(false)
  const [showProductThumb, setShowProductThumb] = useState(false)
  const [toggleTransitions, setToggleTransitions] = useState({
    hasGen8: false,
    hasXtractive: false,
    hasGenS: false,
    hasPolarize: false,
  })

  const dispatch = useDispatch()

  const { loading, run, error, data, setError, setData } = useAsync(
    VTOCatalogServices.getUpcs
  )

  const errorMessage = isMultipleSearch
    ? `No products found for UPC: ${upcNotFound}`
    : invalidProductError
    ? 'The requested UPC is not available for the Virtual Mirror experience'
    : 'Product not found'
  const helperText =
    error || invalidProductError
      ? errorMessage
      : isMultipleSearch && 'Separate UPCs by comma ","'

  const isValidProductImageUrl = (productImageUrl: string): boolean => {
    return !productImageUrl.includes('') ? false : true
  }

  const getMoco = () => {
    if (currentProduct?.code && currentProduct?.colorCode) {
      return `${currentProduct.code} ${currentProduct.colorCode}`
    }

    return ''
  }

  const onLoadBrandLogo = () => setShowBrandLogo(true)
  const onErrorBrandLogo = () => setShowBrandLogo(false)
  const onLoadProductThumb = () => setShowProductThumb(true)
  const onErrorProductThumb = () => setShowProductThumb(false)

  const resetModal = useCallback(() => {
    setProducts([])
    setUpc('')
    setCurrentProduct(defaultProduct)
    setSelectFormatValue('')
    setCatalogImageValue('')
    setData(null)
    setDisabledConfirm(true)
    setToggleTransitions({
      hasGen8: false,
      hasXtractive: false,
      hasGenS: false,
      hasPolarize: false,
    })
  }, [setData])

  const handleChangeUpc = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error && isMultipleSearch) setError(false)
    setUpc(e.target.value)
  }

  const handleSearch = useCallback(
    (search) => {
      resetModal()
      setCurrentProduct(defaultProduct)
      const upcs = sanitazeString(search).split(',')
      run(upcs)
      setUpc(upcs.toString())
    },
    [run, resetModal]
  )

  const handleChangeBrand = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBrand = e.target.value

    setCurrentProduct((c) => ({
      ...(c as Product),
      brand: { name: newBrand, logoUrl: getBrandLogoSrc(newBrand) },
    }))
  }

  const handleChangeProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setCurrentProduct((p) => ({ ...p, [e.target.id]: newValue }))
  }

  const onHandleAdd = () => {
    if (isMultipleSearch && products.length) {
      dispatch(
        addProducts(
          products.map((p) => ({
            ...p,
            pictures: undefined,
            transitionsSupported: undefined,
          }))
        )
      )
    } else if (currentProduct) {
      dispatch(
        addProduct({
          ...currentProduct,
          pictures: undefined,
          transitionsSupported: undefined,
        })
      )
    }
    resetModal()
    onClose()
  }

  const onHandleUpdate = () => {
    if (currentProduct && isValidProductImageUrl(currentProduct.thumbnailUrl)) {
      dispatch(
        updateProduct({
          ...currentProduct,
          pictures: undefined,
          transitionsSupported: undefined,
        })
      )
    }
    resetModal()
    onClose()
  }

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetModal()
    setMultipleSearch(event.target.checked)
  }

  const handleClose = () => {
    resetModal()
    onClose()
  }

  const onSetUpcNotFound = (filteredData: Upcs[], upcs: string[]) => {
    const upcList = filteredData.map((product) => product.upc.id)
    const upcNotFoundList = upcs.filter((upc) => {
      if (!upcList.includes(upc)) return upc
      else return null
    })

    const upcNotFoundFormatted = upcNotFoundList.join(', ')
    setUpcNotFound(upcNotFoundFormatted)
  }

  const onSetError = useCallback(() => {
    const upcs = sanitazeString(upc).split(',')
    const filteredData = data
      ? data.filter(VTOCatalogServices.isValidProduct)
      : null
    if (
      (filteredData && !filteredData.length) ||
      (filteredData && filteredData.length !== upcs.length)
    ) {
      setError(true)
      onSetUpcNotFound(filteredData, upcs)
    } else setError(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setError])

  useEffect(() => {
    if (!upc) setError(false)
  }, [upc, setError])

  useEffect(() => {
    onSetError()
  }, [onSetError])

  useEffect(() => {
    if (data?.length) {
      const filteredData = data.filter(VTOCatalogServices.isValidProduct)
      let url: string
      let p: ProductPayload[] = []
      if (product && product.thumbnailUrl) {
        if (filteredData.length > 0) {
          const { isTransition, transitionLens } = product
          const { availableColors = [] } = transitionLens || {}
          const hasTransitions = !!isTransition && availableColors.length > 0

          const pictures = filteredData[0].pictures
          Object.entries(pictures).forEach(([catalog, value]) => {
            Object.values(value).forEach((item) => {
              const imageType = appendImageSizeParams(
                item.find((url) => product.thumbnailUrl.startsWith(url)) || ''
              )
              if (imageType) {
                setCatalogImageValue(catalog)
                setSelectFormatValue(imageType)
                url = imageType

                const searchProduct = formatProduct([filteredData[0]])[0]
                const isTransitionsSupported =
                  !!searchProduct.transitionsSupported && hasTransitions

                setCurrentProduct({
                  ...searchProduct,
                  thumbnailUrl: url || '',
                  isTransition: isTransitionsSupported,
                  transitionLens: isTransitionsSupported
                    ? {
                        availableColors: availableColors,
                      }
                    : undefined,
                })

                if (isTransitionsSupported) {
                  setToggleTransitions({
                    hasGen8: availableColors.some((x) => getGen8().includes(x)),
                    hasGenS: availableColors.some((x) => getGenS().includes(x)),
                    hasXtractive: availableColors.some((x) =>
                      getXtractive().includes(x)
                    ),
                    hasPolarize: availableColors.some((x) =>
                      getXtractivePolarized().includes(x)
                    ),
                  })
                }
              }
            })
          })
        }
      } else {
        if (filteredData.length > 0) {
          filteredData.forEach((item) => {
            // Order desc keys of pictures field (qt - lt - fr - ...)
            let keySorted = Object.keys(item.pictures).sort((a, b) => {
              if (a > b) return -1
              else return 0
            })

            // use for test
            // keySorted = keySorted.filter(o => o !== 'PI20')

            // Order desc values of pictures fields
            const valueSorted = Object.values(item.pictures[keySorted[0]])
              .sort((a, b) => {
                if (a > b) return -1
                else return 0
              })
              .map((o) =>
                o.sort((a, b) => {
                  if (a > b) return -1
                  else return 0
                })
              )
              .flat()

            switch (keySorted[0]) {
              case 'Degree': {
                url = valueSorted.find((o) => o.indexOf('030') !== -1) || ''
                break
              }
              case '360': {
                url = valueSorted.find((o) => o.indexOf('000A') !== -1) || ''
                break
              }
              case 'P20': {
                url =
                  valueSorted.find((o) => o.indexOf('STD__shad__fr') !== -1) ||
                  ''
                break
              }
              case 'P21': {
                url =
                  valueSorted.find((o) => o.indexOf('P21__shad__fr') !== -1) ||
                  ''
                break
              }
              default: {
                url = valueSorted[0]
                break
              }
            }

            p.push({
              upc: item,
              imageType: appendImageSizeParams(url || ''),
              catalog: keySorted[0],
            })
          })

          if (isMultipleSearch) {
            setProducts(
              formatProduct(p.map(({ upc }) => upc)).map((a, index) => ({
                ...a,
                thumbnailUrl: p[index].imageType || '',
              }))
            )
          } else {
            setCatalogImageValue(p[0].catalog)
            setSelectFormatValue(p[0].imageType)
            setCurrentProduct((x) => ({
              ...formatProduct([p[0].upc]).map((a) => ({
                ...a,
                thumbnailUrl: p[0].imageType || '',
              }))[0],
            }))
          }
        }
      }

      if (filteredData.length) {
        setInvalidProductError(false)
        setDisabledConfirm(false)
      } else {
        setInvalidProductError(true)
      }
    }
  }, [data, isMultipleSearch, product, product?.thumbnailUrl])

  useEffect(() => {
    if (product && isOpen) {
      handleSearch(product.upc)
      setCurrentProduct(product)
    }
  }, [product, isOpen, handleSearch])

  useEffect(() => {
    const hasTransitions = Object.values(toggleTransitions).some(
      (x) => x === true
    )

    if (hasTransitions) {
      setCurrentProduct((p) => ({
        ...p,
        isTransition: true,
        transitionLens: {
          availableColors: [
            ...(toggleTransitions.hasGen8 ? getGen8() : []),
            ...(toggleTransitions.hasXtractive ? getXtractive() : []),
            ...(toggleTransitions.hasGenS ? getGenS() : []),
            ...(toggleTransitions.hasPolarize ? getXtractivePolarized() : []),
          ],
        },
      }))
    } else {
      setCurrentProduct((p) => ({
        ...p,
        isTransition: false,
        transitionLens: undefined,
      }))
    }
  }, [toggleTransitions])

  const [isDisabledConfirm, setDisabledConfirm] = useState(true)

  const [catalogImageValue, setCatalogImageValue] = useState<string>()
  const [selectFormatValues, setSelectFormatValue] = useState('')

  const HandleCatalogueImageChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCatalogImageValue(event.target.value as string)
    setSelectFormatValue('')
    setDisabledConfirm(true)
  }

  const HandleSelectFormatValuesChange = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    const thumbnailUrl = appendImageSizeParams(event.target.value || '')

    setSelectFormatValue(thumbnailUrl)
    setCurrentProduct({
      ...currentProduct,
      thumbnailUrl: thumbnailUrl || '',
    })
    setDisabledConfirm(false)
  }
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <Box className={styles.content}>
        {loading && (
          <Box className={styles.loaderWrapper}>
            <CircularProgress />
          </Box>
        )}
        {!product && (
          <>
            <Typography className={styles.title}>
              Find the product to add:
            </Typography>
            <Box className={styles.searchWrapper}>
              <TextField
                className={styles.searchInput}
                error={error || invalidProductError}
                helperText={helperText}
                id="upc"
                label="Find by UPC"
                placeholder={
                  !isMultipleSearch
                    ? 'e.g. 098989482'
                    : 'e.g. 098989,985849,99...'
                }
                onChange={handleChangeUpc}
                value={upc}
              />
              <Button
                color="primary"
                variant="contained"
                disabled={!upc.length}
                onClick={() => handleSearch(upc)}
              >
                <Search />
                <Typography className={styles.btnText}>search</Typography>
              </Button>
              {
                <Box className={styles.switcherWrapper}>
                  <Typography className={styles.btnText}>
                    Multiple Search
                  </Typography>
                  <Switch
                    checked={isMultipleSearch}
                    onChange={handleChangeSwitch}
                    color="primary"
                    name="multiple search"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
              }
            </Box>
          </>
        )}
        <Typography className={styles.title}>
          {isMultipleSearch ? 'Results:' : 'Edit product properties:'}
        </Typography>

        {isMultipleSearch ? (
          <SearchProductsTable products={products} />
        ) : (
          <>
            <Box className={styles.brandWrapper}>
              <Box className={styles.image}>
                <img
                  style={{
                    display: showBrandLogo ? 'block' : 'none',
                  }}
                  src={getBrandLogoSrc(currentProduct?.brand?.name)}
                  alt="brand logo"
                  onLoad={onLoadBrandLogo}
                  onError={onErrorBrandLogo}
                />
              </Box>
              <TextField
                style={{ flexGrow: 1 }}
                id="brandName"
                label="Brand"
                onInput={handleChangeBrand}
                value={currentProduct?.brand && currentProduct?.brand.name}
              />
            </Box>
            <Box className={styles.productPropsWrapper}>
              <TextField
                className={styles.inputFirstRow}
                id="code"
                label="Model Code"
                onInput={handleChangeProduct}
                value={currentProduct?.code}
              />
              <TextField
                className={styles.inputFirstRow}
                id="colorCode"
                label="Color Code"
                onInput={handleChangeProduct}
                value={currentProduct?.colorCode}
              />
              <TextField
                className={styles.inputFirstRow}
                id="styleName"
                label="Model Name"
                onInput={handleChangeProduct}
                value={currentProduct?.styleName}
              />
              <TextField
                className={styles.inputFirstRow}
                id="size"
                label="Size"
                onInput={handleChangeProduct}
                value={currentProduct?.size}
              />
            </Box>
            <Box className={styles.productPropsWrapper}>
              <TextField
                className={styles.inputSecondRow}
                id="name"
                label="Model and Color - MOCO"
                onInput={handleChangeProduct}
                value={getMoco()}
              />
              <TextField
                className={styles.inputSecondRow}
                id="frameColorLabel"
                label="Frame Color"
                onInput={handleChangeProduct}
                value={currentProduct?.frameColorLabel}
              />
              <TextField
                className={styles.inputSecondRow}
                id="lensColorLabel"
                label="Lens Color"
                onInput={handleChangeProduct}
                value={currentProduct?.lensColorLabel}
              />
            </Box>
            {currentProduct.transitionsSupported && (
              <>
                <Typography className={styles.lensLabel}>
                  Select the Transitions lenses:
                </Typography>
                <Box className={styles.transitionsWrapper}>
                  <Typography className={styles.transitionsLabel}>
                    Xtractive Polarized
                  </Typography>
                  <Switch
                    checked={toggleTransitions.hasPolarize}
                    onChange={() =>
                      setToggleTransitions((x) => ({
                        ...x,
                        hasPolarize: !x.hasPolarize,
                      }))
                    }
                    color="primary"
                    name="transitions-switch"
                  />
                  <div className={styles.separator}>|</div>
                  <Typography className={styles.transitionsLabel}>
                    Signature Gen8
                  </Typography>
                  <Switch
                    checked={toggleTransitions.hasGen8}
                    onChange={() =>
                      setToggleTransitions((x) => ({
                        ...x,
                        hasGen8: !x.hasGen8,
                      }))
                    }
                    color="primary"
                    name="transitions-switch"
                  />
                  <div className={styles.separator}>|</div>
                  <Typography className={styles.transitionsLabel}>
                    GenS
                  </Typography>
                  <Switch
                    checked={toggleTransitions.hasGenS}
                    onChange={() =>
                      setToggleTransitions((x) => ({
                        ...x,
                        hasGenS: !x.hasGenS,
                      }))
                    }
                    color="primary"
                    name="transitions-switch"
                  />
                  <div className={styles.separator}>|</div>
                  <Typography className={styles.transitionsLabel}>
                    Xtractive
                  </Typography>
                  <Switch
                    checked={toggleTransitions.hasXtractive}
                    onChange={() =>
                      setToggleTransitions((x) => ({
                        ...x,
                        hasXtractive: !x.hasXtractive,
                      }))
                    }
                    color="primary"
                    name="transitions-switch"
                  />
                </Box>
              </>
            )}
            <Typography className={styles.infoAppearenceLabel}>
              Please note that the <b>Model Name</b>, <b>Frame Color</b> and
              <b> Lens Color</b> will be displayed on the polaroid
            </Typography>
            <Box className={styles.imageWrapper}>
              <Box className={styles.imageProduct}>
                {loadingCatalogImage && (
                  <Box className={styles.loaderWrapper}>
                    <CircularProgress />
                  </Box>
                )}
                <img
                  width={240}
                  height={240}
                  style={{
                    display: showProductThumb ? 'block' : 'none',
                  }}
                  src={selectFormatValues}
                  alt="thumb"
                  onLoad={() => {
                    setLoadingCatalogImage(false)
                    onLoadProductThumb()
                  }}
                  onError={onErrorProductThumb}
                />
              </Box>
              <Box className={styles.selectsContainer}>
                <FormControl className={styles.select} variant="outlined">
                  <InputLabel id="select-style">
                    Select image catalogue
                  </InputLabel>
                  <Select
                    labelId="select-style"
                    id="select-style"
                    displayEmpty={true}
                    value={catalogImageValue || ''}
                    label="Select image catalogue"
                    onChange={HandleCatalogueImageChange}
                  >
                    {currentProduct &&
                      currentProduct.pictures &&
                      Object.keys(currentProduct.pictures)
                        .reverse()
                        .map((value, key) => {
                          return (
                            <MenuItem
                              key={key}
                              value={value}
                              className={styles.menuItem}
                            >
                              {value}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </FormControl>
                <FormControl className={styles.select} variant="outlined">
                  <InputLabel id="select-style">Select image type</InputLabel>
                  <Select
                    labelId="select-style"
                    id="select-style"
                    value={selectFormatValues || ''}
                    disabled={!catalogImageValue}
                    label="Select image type"
                    onChange={(e) => {
                      setLoadingCatalogImage(true)
                      HandleSelectFormatValuesChange(e)
                    }}
                  >
                    {currentProduct &&
                      currentProduct.pictures &&
                      Object.entries(currentProduct.pictures)
                        .filter((keys) => keys[0] === catalogImageValue)
                        .map(([, value]) =>
                          Object.entries(value).map((k) => {
                            return Object.values(k[1] as Array<string>)
                              .flat()
                              .map((y, z) => {
                                return (
                                  <MenuItem
                                    key={z}
                                    value={appendImageSizeParams(y)}
                                    className={styles.menuItem}
                                  >
                                    {
                                      y
                                        .toString()
                                        .replace('.jpg', '')
                                        .split('/') //.split(`${currentProduct.code}/`)
                                        .pop()
                                        ?.split('.png')[0]
                                    }
                                  </MenuItem>
                                )
                              })
                          })
                        )}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </>
        )}

        <Box className={styles.btnsWrapper}>
          <Button onClick={handleClose} style={{ marginRight: '2rem' }}>
            <Typography className={styles.btnText}>cancel</Typography>
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isDisabledConfirm}
            onClick={!product ? onHandleAdd : onHandleUpdate}
          >
            <Typography className={styles.btnText}>confirm and add</Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

import { Aside } from '../components/core/Aside'
import { BaseComponent } from '../components/core/BaseComponent'
import { PageComponent } from '../components/core/PageComponent'
import { ListingGroup } from '../components/ListingGroups'

const GroupListPage = () => {
  return (
    <BaseComponent>
      <Aside titleLabel={() => <></>} textLabel={() => <></>} />
      <PageComponent>
        <ListingGroup />
      </PageComponent>
    </BaseComponent>
  )
}

export default GroupListPage

import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Stepper, Step, StepLabel, Box, Typography } from '@material-ui/core'
import { getWizardSteps } from '../pages/EditPage'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { format, isValid } from 'date-fns'

const ExperienceAdditionalInfo: React.FC = () => {
  const { name, region, channel, style } = useSelector((state) => ({
    name: state.vmData.name,
    region: state.vmData.analytics.storeRegion,
    channel: state.vmData.analytics.pageBrand,
    style: state.vmData.analytics.storeCompany,
  }))

  if (!name || !region || !channel || !style) return null

  return (
    <>
      <Typography>Name: {name}</Typography>
      <Typography>Region: {region}</Typography>
      <Typography>Channel: {channel}</Typography>
      <Typography>Style: {style}</Typography>
    </>
  )
}

const ExpirationAdditionalInfo: React.FC = () => {
  const { store, expire } = useSelector(
    (state) => ({ store: state.vmData.store, expire: state.vmData.expire }),
    shallowEqual
  )
  if (!store.storeId.length || !store.type.length) return null

  return (
    <>
      <Typography>Store ID: {store.storeId}</Typography>
      <Typography>Type: {store.type}</Typography>
      {expire && (
        <Typography>
          Expire:{' '}
          {isValid(new Date(expire))
            ? format(new Date(expire), 'eee dd MMM yyyy HH:mm')
            : expire}
        </Typography>
      )}
    </>
  )
}

const ProductsAdditionalInfo: React.FC = () => {
  const products = useSelector((state) => state.vmData.products)
  if (!products.length) return null

  return <Typography>{products.length} Selected</Typography>
}

const AdditionalInfo: React.FC<{ step: string }> = ({ step }) => {
  switch (step) {
    case 'experience info':
      return <ExperienceAdditionalInfo />
    case 'expiration':
      return <ExpirationAdditionalInfo />
    case 'products':
      return <ProductsAdditionalInfo />
    default:
      return null
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      width: '100%',
      paddingBottom: '8rem',
    },
    backButton: {
      marginRight: '1rem',
    },
    stepper: {
      padding: 0,
    },
    text: {
      marginTop: '1.175rem',
      textTransform: 'uppercase',
      fontSize: '1.175rem',
      fontWeight: 700,
    },
    stepLabel: {
      position: 'relative',
      cursor: 'pointer',
    },
    additionInfo: {
      position: 'absolute',
      width: '100%',
      top: '110%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  })
)

export const WizardStepperHeader: React.FC = () => {
  const styles = useStyles()
  const match = useRouteMatch<{ step: string }>()
  const step = match.params.step
  const steps = getWizardSteps()
  const history = useHistory()
  const wizardActiveIndex = steps.findIndex((wizard) => wizard.step === step)

  const handleClick = (step: string | null) => {
    if (step) {
      history.replace(match.path.replace(':step', step))
    }
  }

  return (
    <Box className={styles.content}>
      <Stepper
        activeStep={wizardActiveIndex}
        alternativeLabel
        className={styles.stepper}
      >
        {steps.map(({ step }, ind) => (
          <Step key={step}>
            <StepLabel
              className={styles.stepLabel}
              onClick={() => handleClick(wizardActiveIndex > ind ? step : null)}
            >
              <Box className={styles.text}>{step}</Box>
              {wizardActiveIndex > ind && (
                <Box className={styles.additionInfo}>
                  <AdditionalInfo step={step} />
                </Box>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

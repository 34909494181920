import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'

import version from '../version'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 10px',
    },
    info: {
      display: 'flex',
      fontSize: '10px',
      '& label': {
        minWidth: '100px',
        textAlign: 'right',
        marginRight: '5px',
      },
    },
  })
)

export const Version: React.FC<{ className?: string }> = ({ className }) => {
  const styles = useStyles()
  const [apiVersion, setApiVersion] = useState('')

  useEffect(() => {
    let apiURL = process.env.REACT_APP_VM_API_URL

    if (apiURL && apiURL.endsWith('/api')) {
      apiURL = apiURL.substring(0, apiURL.lastIndexOf('/api'))
    }

    if (apiURL)
      fetch(`${apiURL}/api-version`).then(async (res) => {
        if (res.ok) {
          const v = await res.text()
          setApiVersion(v)
        }
      })
  }, [])

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.info}>
        <label>App version:</label>
        {version}
      </div>
      <div className={styles.info}>
        <label>API version:</label> {apiVersion}
      </div>
    </div>
  )
}

import { Box, TextField } from '@material-ui/core'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import DateFnsUtils from '@date-io/date-fns'
import React from 'react'
import { TitleEdit } from '../components/core/TitleEdit'
import { Today } from '@material-ui/icons'
import { WizardStepperFooter } from '../components/WizardStepperFooter'
import { isQAModeEnabled } from '../libs/utils'
import { setAnalyticsData } from '../features/analytics/analyticsSlice'
import { setExpirationData } from '../features/expiration/expirationSlice'
import { setStoreData } from '../features/store/storeSlice'
import { startOfTomorrow } from 'date-fns'
import { colorPalette } from '../styleVariables'

const useStyles = makeStyles(() =>
  createStyles({
    hideContent: {
      display: 'none',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '25vw',
      width: '100%',
      margin: '0 auto 5rem',
    },
    input: {
      marginBottom: '2rem',
    },
    datePickerWrapper: {
      width: '100%',
      position: 'relative',
      marginBottom: '5rem',
      whiteSpace: 'nowrap',
    },
    datePicker: {
      width: '100%',
    },
    datePickerWarning: {
      fontSize: '12px',
      color: colorPalette.greyDark,
    },
    iconPicker: {
      position: 'absolute',
      /* input bottom padding */
      top: '7px',
      right: 0,
      bottom: 0,
      margin: 'auto',
      width: '2rem',
      height: '2rem',
    },
  })
)

export const EditExpirationPage: React.FC = () => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const { expireDate, storeId, storeType, analyticsStoreID } = useSelector(
    (state) => ({
      expireDate: state.vmData.expire,
      storeId: state.vmData.store.id,
      storeType: state.vmData.store.type,
      analyticsStoreID: state.vmData.analytics.storeId,
    }),
    shallowEqual
  )

  const handleDateChange = (date: Date | null) => {
    const formatedDate = date && date.toISOString()
    if (formatedDate) dispatch(setExpirationData(formatedDate))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id !== 'analytics') {
      const data = {
        storeId: storeId,
        type: storeType,
        [e.target.id]: e.target.value,
      }
      dispatch(setStoreData(data))
    } else {
      dispatch(setAnalyticsData({ storeId: e.target.value }))
    }
  }

  const isStepValid = !!expireDate.length

  return (
    <Box className={styles.content}>
      <Box className={styles.hideContent}>
        <TitleEdit title="Properties" />
        <TextField
          id="storeId"
          label="Store ID"
          className={styles.input}
          value={storeId}
          onChange={handleChange}
        />
        <TextField
          id="type"
          label="Store type"
          className={styles.input}
          value={storeType}
          onChange={handleChange}
        />
        <TextField
          id="analytics"
          label="Analytics Store Identifier"
          className={styles.input}
          value={analyticsStoreID}
          onChange={handleChange}
        />
      </Box>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box className={styles.datePickerWrapper}>
          <DateTimePicker
            ampm={false}
            margin="normal"
            id="date-picker-dialog"
            label="Expiration Date Time"
            format="dd/MM/yyyy HH:mm"
            value={expireDate || null}
            onChange={handleDateChange}
            minDate={!isQAModeEnabled() ? startOfTomorrow() : null}
            disablePast
            helperText={<span className={styles.datePickerWarning}></span>}
            className={styles.datePicker}
          />
          <Today className={styles.iconPicker} />
        </Box>
      </MuiPickersUtilsProvider>
      <WizardStepperFooter isValid={isStepValid} />
    </Box>
  )
}

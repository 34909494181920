import React, { useCallback, useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { TitleEdit } from '../components/core/TitleEdit'
import { WizardStepperFooter } from '../components/WizardStepperFooter'
import { LinksTable } from '../components/LinksTable'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      flexGrow: 1,
    },
    title: {
      marginBottom: 0,
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '2rem',
    },
    btn: {
      padding: '0.5rem 2rem',
      borderRadius: '3rem',
    },
    btnQr: {
      padding: '0.5rem 2rem',
      borderRadius: '3rem',
      marginRight: '1rem',
    },
    btnText: {
      fontSize: '1.35rem',
      fontWeight: 500,
    },
    tableWrapper: {
      marginBottom: '2rem',
    },
    linksTableNoteContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

export const EditLinksPage: React.FC = () => {
  const styles = useStyles()
  const [isValid, setIsValid] = useState(true)

  const onValidated = useCallback((isValid: boolean) => {
    setIsValid(isValid)
  }, [])

  return (
    <Box className={styles.content}>
      <TitleEdit className={styles.title} title="Related resources" />
      <Box className={styles.header}>
        <Box className={styles.linksTableNoteContainer}>
          <Typography style={{ textAlign: 'center' }}>
            By filling this form, the user can be redirected to the inserted
            link page(s) and continue their experience. <br /> Please note that
            you can add up to 2 links to external websites. <br /> This
            functionality is not mandatory
          </Typography>
        </Box>
      </Box>
      <Box className={styles.tableWrapper}>
        <LinksTable onValidated={onValidated} />
      </Box>
      <WizardStepperFooter isValid={isValid} />
    </Box>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LinkHeader } from '../../types/vmdata'

const initialState: LinkHeader = {}

const linksHeader = createSlice({
  name: 'vm-bo/linksHeader',
  initialState,
  reducers: {
    setLinksHeader: (_, action: PayloadAction<LinkHeader | undefined>) => {
      const { title, subTitle } = action.payload || {}
      return {
        title,
        subTitle,
      }
    },
  },
})

export const { setLinksHeader } = linksHeader.actions
export default linksHeader.reducer

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import { DeleteUserDialog } from './DeleteUserDialog'
import { Group } from './ListingGroups'
import GroupServices from '../services/groupServices'
import UserServices from '../services/userServices'
import { UserTableBody } from './core/UserTableBody'
import { UserTablePopup } from './core/UserTablePopup'
import { Users } from './UserList'
import { colorPalette } from '../styleVariables'
import { makeStyles } from '@material-ui/core/styles'

interface Column {
  id:
    | 'username'
    | 'surname'
    | 'name'
    | 'email'
    | 'role'
    | 'group'
    | 'dateAdded'
    | 'actions'
  label: string
  formatDate?: string
  minWidth?: number
}

const columns: Column[] = [
  {
    id: 'username',
    label: 'Username',
  },
  { id: 'surname', label: 'Surname' },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  { id: 'role', label: 'Role' },
  { id: 'group', label: 'Group' },
  {
    id: 'dateAdded',
    label: 'Date Added',
    formatDate: 'dd/MM/yyyy HH:mm',
  },
  { id: 'actions', label: 'Actions' },
]

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '70vh',
    minHeight: '70vh',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    overflow: 'auto',
    position: 'relative',
  },
  rowHead: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
  },
  columnHeadCell: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  emptyMessageWrapper: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exmptyMessage: {
    fontSize: '2rem',
    color: colorPalette.greyDark,
  },
  svg: {
    fill: colorPalette.blue,
    width: '1.85rem',
    height: '1.85rem',
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
})

const EmptyMessage: React.FC = () => {
  const styles = useStyles()

  return (
    <Box className={styles.emptyMessageWrapper}>
      <Typography className={styles.exmptyMessage}>
        Your list is empty!
      </Typography>
    </Box>
  )
}

interface ListingTableProps {
  createStatus: boolean
  setCreateStatus: (s: boolean) => void
  editableStatus: boolean
  setEditableStatus: (s: boolean) => void
  users: Users[] | undefined
  searchValue: string
  refetchUsers: () => Promise<void>
  byGroup?: boolean
}

export const UserTable: React.FC<ListingTableProps> = ({
  createStatus,
  setCreateStatus,
  editableStatus,
  setEditableStatus,
  users,
  searchValue,
  refetchUsers,
  byGroup,
}) => {
  const styles = useStyles()
  const [loading, setLoading] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [rowToDelete, setRowToDelete] = useState<Users | null>(null)
  const [isEdited, setisEdited] = useState(false)
  const [deletedStatus, setDeletedStatus] = useState(false)
  const [deletedStatusPermanent, setDeletedStatusPermanent] = useState(false)
  const [availableGroups, setAvailableGroups] = useState<Group[]>()
  const [isAdded, setisAdded] = useState(false)
  const [isChecked, setisChecked] = useState(false)
  const [rowToAdd, setRowToAdd] = useState<Users>({
    id: '',
    username: '',
    surname: '',
    name: '',
    email: '',
    role: '',
    groupId: '',
  })
  const [rowToEdit, setRowToEdit] = useState<Users>({
    id: '',
    username: '',
    surname: '',
    name: '',
    email: '',
    role: '',
    groupId: '',
  })

  const onOpenPopup = (row: Users) => {
    setRowToDelete(row)
    if (!row.groupId) {
      setisChecked(true)
    }
    setIsOpenPopup(true)
  }

  const onClosePopup = () => {
    setIsOpenPopup(false)
    setisChecked(false)
  }

  const handleDelete = async () => {
    if (rowToDelete !== null) {
      setLoading(true)
      await UserServices.deleteUser(rowToDelete.id)
      onClosePopup()
      await refetchUsers()
      setLoading(false)
      setDeletedStatusPermanent(true)
    }
  }

  const handleEdit = async (row: Users) => {
    if (rowToEdit && rowToEdit.name !== '') {
      setEditableStatus(false)
      setisEdited(true)
      const { createdBy, group, ...user } = rowToEdit
      setLoading(true)
      await UserServices.updateUser(rowToEdit.id, user)
      await refetchUsers()
    }
    setLoading(false)
  }

  const handleDeleteFromGroup = async () => {
    if (rowToDelete) {
      const { createdBy, group, createdAt, ...user } = rowToDelete
      user.groupId = null
      setLoading(true)
      await UserServices.updateUser(rowToDelete.id, user)
      onClosePopup()
      await refetchUsers()
      setLoading(false)
      setDeletedStatus(true)
    }
  }

  const handleAddGroup = async () => {
    if (rowToAdd && rowToAdd.name !== '') {
      const { id, createdAt, createdById, ...user } = rowToAdd
      setLoading(true)
      await UserServices.insertUser(user)
      await refetchUsers()
      setLoading(false)
      setisAdded(true)
      setCreateStatus(false)
    }
  }

  const handleAddGroupName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    const value = e.target.value
    setRowToAdd({ ...rowToAdd, [field]: value || '' })
  }

  const handleAddGroupNameSelect = (value: string, field: string) => {
    setRowToAdd({ ...rowToAdd, [field]: value || '' })
  }

  const handleChangeGroupName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    const value = e.target.value
    setRowToEdit({ ...rowToEdit, [field]: value || '' })
  }

  const handleChangeGroupNameSelect = (value: string, field: string) => {
    setRowToEdit({ ...rowToEdit, [field]: value || '' })
  }

  const roles = ['Admin', 'User']
  useEffect(() => {
    ;(async () => {
      const groups = await GroupServices.getAllGroups()
      setAvailableGroups(groups)
    })()
  }, [])

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTo(0, 0)
    }
  }, [createStatus])

  return (
    <>
      <Paper className={styles.root}>
        <div className={styles.container} ref={ref}>
          <Table stickyHeader>
            <TableHead className={styles.rowHead}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className={styles.columnHeadCell}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <UserTableBody
              createStatus={createStatus}
              handleAddGroupName={handleAddGroupName}
              handleAddGroupNameSelect={handleAddGroupNameSelect}
              handleChangeGroupName={handleChangeGroupName}
              handleChangeGroupNameSelect={handleChangeGroupNameSelect}
              roles={roles}
              availableGroups={availableGroups}
              users={users}
              setCreateStatus={setCreateStatus}
              handleAddGroup={handleAddGroup}
              searchValue={searchValue}
              setRowToEdit={setRowToEdit}
              rowToAdd={rowToAdd}
              rowToEdit={rowToEdit}
              onOpenPopup={onOpenPopup}
              handleEdit={handleEdit}
              editableStatus={editableStatus}
              setEditableStatus={setEditableStatus}
            />
          </Table>
          {loading && (
            <Box className={styles.loaderWrapper}>
              <CircularProgress />
            </Box>
          )}
          {!users?.length && <EmptyMessage />}
        </div>
      </Paper>
      <DeleteUserDialog
        isOpenPopup={isOpenPopup}
        onClosePopup={onClosePopup}
        isChecked={isChecked}
        rowToDelete={rowToDelete}
        setisChecked={setisChecked}
        handleDelete={handleDelete}
        handleDeleteFromGroup={handleDeleteFromGroup}
      />
      <UserTablePopup
        setDeletedStatus={setDeletedStatus}
        setDeletedStatusPermanent={setDeletedStatusPermanent}
        setRowToDelete={setRowToDelete}
        setEditableStatus={setEditableStatus}
        setRowToEdit={setRowToEdit}
        setRowToAdd={setRowToAdd}
        setisEdited={setisEdited}
        setisAdded={setisAdded}
        setCreateStatus={setCreateStatus}
        deletedStatus={deletedStatus}
        deletedStatusPermanent={deletedStatusPermanent}
        isEdited={isEdited}
        isAdded={isAdded}
        rowToAdd={rowToAdd}
        rowToDelete={rowToDelete}
        isChecked={isChecked}
        setisChecked={setisChecked}
        rowToEdit={rowToEdit}
      />
    </>
  )
}

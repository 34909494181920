import brandList from '../assets/json/brands.json'

export const getHashFromUrl = (url: string): string | null => {
  const hash = url.match(/\.com\/?(?:\?hash=|hash=)?([0-9A-Za-z]+)/)
  return hash ? hash[1] : null
}

export const sanitazeString = (str: string): string =>
  str.replace(/(^,)|(,$)|( )/g, '')

export const copyToClipboard = (text: string): void => {
  const el = document.createElement('textarea')
  el.value = text
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  el.style.zIndex = '200000'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const getVmUrl = (hash: string): string | undefined =>
  !!hash ? `${process.env.REACT_APP_VM_URL}/${hash}` : undefined

export const getAssetImage = (basePath: string): string =>
  [process.env.REACT_APP_ASSETS_URL, basePath].join('/')

export const getThumbUrl = (basePath: string): string =>
  [process.env.REACT_APP_THUMB_URL, basePath].join('/')

export const getUpcAvailabilityApi = (upcs: string[]): string =>
  `${process.env.REACT_APP_UPC_AVAILABILITY}/${upcs.join(',')}/availability`

export const isQAModeEnabled = (): boolean => {
  if (window.sessionStorage) {
    const sessionStorage = window.sessionStorage
    const qaMode = sessionStorage.getItem('QA-mode')

    return qaMode === 'true' || false
  }

  return false
}

export const getBrandLogoFileName = (
  brandToFind: string | undefined
): string => {
  if (!brandToFind) return 'no_logo'

  return (
    brandList.find(
      (brand) => brand?.name?.toLowerCase() === brandToFind?.toLowerCase()
    )?.id || 'no_logo'
  )
}

export const getBrandLogoSrc = (brand: string | undefined): string =>
  getAssetImage(`brands/${getBrandLogoFileName(brand)}.svg`)

export const appendImageSizeParams = (url: string) => {
  try {
    const resizeImageUrl = new URL(url)
    resizeImageUrl.searchParams.set('imwidth', '200')

    return resizeImageUrl.toString()
  } catch {}

  return url
}

export const isValidUrl = (url: string) => {
  if (!url.startsWith('https://') && !url.startsWith('http://')) return false

  const regex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i

  return regex.test(url)
}

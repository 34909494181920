import { Aside } from '../components/core/Aside'
import { BaseComponent } from '../components/core/BaseComponent'
import { PageComponent } from '../components/core/PageComponent'
import { UserList } from '../components/UserList'

const GroupPage = () => {
  return (
    <BaseComponent>
      <Aside titleLabel={() => <></>} textLabel={() => <></>} />
      <PageComponent>
        <UserList />
      </PageComponent>
    </BaseComponent>
  )
}

export default GroupPage

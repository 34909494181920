import { RootState } from '../app/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { getVmUrl } from '../libs/utils'
import { ShortUrlData } from '../types/vmlistingdata'

export const selectHash = (state: RootState): string => state.qrCode.hash
export const selectIsLoadingHash = (state: RootState): boolean =>
  state.qrCode.isLoading

export const selectVmUrl = createSelector(selectHash, (hash) => getVmUrl(hash))

export const selectShortUrl = (state: RootState): ShortUrlData | undefined =>
  state.qrCode.urlShort && state.qrCode.urlShort.key
    ? state.qrCode.urlShort
    : undefined

export const selectQrCodetUrl = (state: RootState): string | undefined => {
  const { urlShort } = state.qrCode
  if (urlShort && urlShort.key) {
    return [urlShort.baseUrl, urlShort.key].join('/')
  }
}

import { RootState } from '../app/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import { VMDataExperience } from '../types/vmdata'

export const selectVmDataExperience = (state: RootState): VMDataExperience =>
  state.vmData

export const selectVmDataPreview = createSelector(
  selectVmDataExperience,
  (vmDataExperience) => ({
    ...vmDataExperience,
    expired: undefined,
  })
)

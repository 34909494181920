import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Button, Box, Typography } from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { getWizardSteps } from '../pages/EditPage'
import { useHistory, useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    contentWithOneBtn: {
      justifyContent: 'center',
    },
    btn: {
      padding: '0.5rem 2rem',
    },
    btnText: {
      fontSize: '1.35rem',
      fontWeight: 500,
    },
  })
)

interface WizardStepperFooterProps {
  isValid: boolean
  isNewProduct?: boolean
}

export const WizardStepperFooter: React.FC<WizardStepperFooterProps> = ({
  isValid,
  isNewProduct,
}) => {
  const styles = useStyles()
  const match = useRouteMatch<{ step: string }>()
  const step = match.params.step
  const history = useHistory()
  const steps = getWizardSteps()
  const wizardActiveIndex = steps.findIndex((wizard) => wizard.step === step)

  const handleNext = () => {
    const nextStep = steps[wizardActiveIndex + 1].step
    history.replace(match.path.replace(':step', nextStep))
  }

  const handleBack = () => {
    const previousStep = steps[wizardActiveIndex - 1].step
    history.replace(match.path.replace(':step', previousStep))
  }

  return (
    <Box
      className={`${styles.content} ${
        (wizardActiveIndex === 0 && styles.contentWithOneBtn) || ''
      }`}
    >
      {wizardActiveIndex !== 0 && (
        <Button
          variant="contained"
          disabled={wizardActiveIndex === 0}
          onClick={handleBack}
          className={styles.btn}
        >
          <ArrowBackIos />
          <Typography className={styles.btnText}>prev</Typography>
        </Button>
      )}

      <Button
        color={wizardActiveIndex === steps.length - 2 ? 'primary' : 'secondary'}
        variant="contained"
        onClick={handleNext}
        disabled={wizardActiveIndex === steps.length - 1 || !isValid}
        className={styles.btn}
      >
        <Typography className={styles.btnText}>
          {wizardActiveIndex === steps.length - 2
            ? isNewProduct
              ? 'generate'
              : 'modify'
            : 'next'}
        </Typography>
        <ArrowForwardIos />
      </Button>
    </Box>
  )
}

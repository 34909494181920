import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../../types/vmdata'

const initialState: Store = {
  id: '',
  storeId: '',
  type: '',
  name: '',
  address: '',
  standAlone: true,
  logo: '',
}

const store = createSlice({
  name: 'vm-bo/store',
  initialState,
  reducers: {
    setStoreData: (state, action: PayloadAction<Omit<Store, 'id'>>) => {
      return {
        ...state,
        ...action.payload,
        id: action.payload.storeId,
        standAlone: true,
      }
    },
    setStoreLogo: (state, action: PayloadAction<string>) => {
      return (state = { ...state, logo: action.payload })
    },
  },
})

export const { setStoreData, setStoreLogo } = store.actions
export default store.reducer

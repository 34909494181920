import { ShortUrlData, VMListData } from '../types/vmlistingdata'
import { UUID, VMData, VMDataExperience } from '../types/vmdata'
import { getUpcAvailabilityApi } from '../libs/utils'

export interface Upcs {
  upc: Upc
  brand: Brand
  color: Color
  frame: Frame
  image?: Image
  lens: Lens
  model: Color
  shape: Brand
  sizing: Sizing
  style: Brand
  description: Description
  pictures: Pictures
  transitionsSupported?: boolean
}

export interface UpcAvailability {
  [upc: string]: {
    available: boolean
    transitions: boolean
  }
}

export interface ProductPayload {
  upc: Upcs
  imageType: string
  catalog: string
}
export interface Brand {
  name: string
}

export interface Color {
  code: string
}

export interface Description {
  text?: string | null | undefined
}

export interface Frame {
  color: string
  material: string
}

export interface Image {
  url: string
  frontUrl: string
  angleUrl: string
  sideUrl: string
}

export interface Lens {
  color: string
  category?: string
}

export interface Sizing {
  hingeDistance?: number
  lensBridgeSize: string
  templeLength: number
}

export interface Pictures {
  [key: string]: {
    [key: string]: string[]
  }
}

export interface Upc {
  id: string
}

export class VTOCatalogError extends Error {
  code: string | undefined
  description: string | undefined

  constructor(message: string, code?: string, description?: string) {
    super(message)
    this.code = code
    this.description = description
  }
}

const appSecret = process.env.REACT_APP_SECRET || ''

export default class VTOCatalogServices {
  static authHeaders() {
    const userToken = localStorage.getItem('userToken')

    return {
      ...this.commonHeaders(),
      Authorization: `Bearer ${userToken}`,
    }
  }

  static commonHeaders() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  static apiBaseURL(): string {
    const apiURL = process.env.REACT_APP_VM_API_URL

    if (!apiURL) {
      throw new VTOCatalogError(
        'REACT_APP_VM_API_URL is not defined in env variables'
      )
    }

    return apiURL
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  static isValidProduct(product: any): product is Upcs {
    return !!product?.upc?.id
  }

  static async getUpcs(upcs: string[]): Promise<Upcs[]> {
    const getProductData = (): Promise<Upcs[]> => {
      const apiUrl = VTOCatalogServices.apiBaseURL()

      return fetch(
        `${apiUrl.replace(/\/$/, '')}/productCatalog/${encodeURI(
          upcs.join(',')
        )}`,
        {
          headers: VTOCatalogServices.authHeaders(),
        }
      ).then(async (response) => {
        if (!response.ok) {
          const body = await response.text()

          throw new VTOCatalogError(
            `VTOCatalog: Failed during JSON parsing ${response.statusText}`,
            response.status.toString(),
            body
          )
        }

        return await response.json()
      })
    }

    const getUpcAvailability = (): Promise<UpcAvailability | null> => {
      return fetch(getUpcAvailabilityApi(upcs)).then(async (response) => {
        if (!response.ok) {
          return null
        }

        return await response.json()
      })
    }

    try {
      const data = await Promise.all([getProductData(), getUpcAvailability()])
      const [upcsData, upcAvailability] = data

      return upcsData.map((upcData) => {
        if (
          !!upcData &&
          upcAvailability !== null &&
          typeof upcAvailability === 'object'
        ) {
          const id = upcData?.upc?.id

          if (id && upcAvailability[id]?.transitions) {
            upcData.transitionsSupported = true
          }
        }

        return upcData
      })
    } catch (e: any) {
      throw new VTOCatalogError(e, '500', e)
    }
  }

  static async getVMDataByUUID(
    uuid: string
  ): Promise<VMDataExperience | undefined> {
    const apiUrl = VTOCatalogServices.apiBaseURL()

    const response = await fetch(
      `${apiUrl.replace(/\/$/, '')}/virtualmirror/${uuid}`
    )

    if (!response.ok) {
      throw new VTOCatalogError(
        'Error on retrieving VM Data',
        response.status.toString()
      )
    }

    if (response.ok) {
      try {
        const vmDataExperience: VMDataExperience = await response.json()
        return vmDataExperience
      } catch (err) {
        throw new VTOCatalogError(err.message)
      }
    }
  }

  static async createHash(vmData: VMData, uuid?: string | null): Promise<UUID> {
    const apiUrl = VTOCatalogServices.apiBaseURL()

    const url = uuid
      ? `${apiUrl.replace(/\/$/, '')}/virtualmirror/${uuid}`
      : `${apiUrl.replace(/\/$/, '')}/virtualmirror`

    const response = await fetch(url, {
      headers: VTOCatalogServices.authHeaders(),
      method: 'put',
      body: JSON.stringify(vmData),
    })

    if (!response.ok) {
      throw new VTOCatalogError(
        'Error on retrieving VM hash',
        response.status.toString()
      )
    }

    try {
      const uuid: UUID = await response.json()
      return uuid
    } catch (err) {
      throw new VTOCatalogError(err.message)
    }
  }

  static async getListingData(
    onUnauthorized: () => void
  ): Promise<VMListData[]> {
    const apiUrl = VTOCatalogServices.apiBaseURL()

    const response = await fetch(`${apiUrl.replace(/\/$/, '')}/listingData`, {
      headers: VTOCatalogServices.authHeaders(),
    })

    if (!response.ok) {
      if (response.status === 401) {
        onUnauthorized()
      }
      throw new VTOCatalogError(
        'Error on retrieving listing data',
        response.status.toString()
      )
    }

    try {
      const listingData: VMListData[] = await response.json()
      return listingData
    } catch (err) {
      throw new VTOCatalogError(err.message)
    }
  }

  static async addListingData(
    listingData: Omit<VMListData, 'createdAt'>
  ): Promise<void> {
    const apiUrl = VTOCatalogServices.apiBaseURL()

    const response = await fetch(`${apiUrl.replace(/\/$/, '')}/listingData`, {
      headers: VTOCatalogServices.authHeaders(),
      method: 'post',
      body: JSON.stringify(listingData),
    })

    if (!response.ok) {
      throw new VTOCatalogError(
        'Error on adding listing data',
        response.status.toString()
      )
    }
  }

  static async deleteListingData(listingData: VMListData): Promise<void> {
    const apiUrl = VTOCatalogServices.apiBaseURL()

    const response = await fetch(`${apiUrl.replace(/\/$/, '')}/listingData`, {
      headers: VTOCatalogServices.authHeaders(),
      method: 'delete',
      body: JSON.stringify(listingData),
    })

    if (!response.ok) {
      throw new VTOCatalogError(
        'Error on deleting listing data',
        response.status.toString()
      )
    }
  }

  static async setShortUrl(
    token: string,
    vmurl: string,
    key?: string
  ): Promise<ShortUrlData> {
    if (!process.env.REACT_APP_VM_API_URL)
      throw new Error('Impossible to find REACT_APP_VM_API_URL')

    let serviceUrl = `${process.env.REACT_APP_VM_API_URL}/urlShortener`
    let method = 'POST'
    // edit mode
    if (key) {
      serviceUrl = `${serviceUrl}/${key}`
      method = 'PUT'
    }

    const response = await fetch(serviceUrl, {
      method,
      headers: {
        ...VTOCatalogServices.authHeaders(),
        'app-secret': appSecret,
      },
      body: JSON.stringify({ token, url: vmurl, createdBy: 'TEst' }),
    })
    if (!response.ok) {
      throw new VTOCatalogError(
        'Error on setting short url',
        response.status.toString()
      )
    }

    try {
      const data: ShortUrlData = await response.json()
      return data
    } catch (err) {
      throw new VTOCatalogError(err.message)
    }
  }
}

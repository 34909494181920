import { Box, Button, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { AppRoutes } from '../../app/routers'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { Popup } from '../Popup'
import { UserInfo } from '../UserInfo'
import { colorPalette } from '../../styleVariables'
import { resetApp } from '../../features/reset/resetAppSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    aside: {
      maxWidth: '25vw',
      backgroundImage: 'linear-gradient(to bottom right, #005EA2, #00135A)',
      color: colorPalette.white,
    },
    btnHome: {
      color: colorPalette.white,
      fontSize: '1rem',
      justifyContent: 'start',
      marginBottom: '4rem',
      fontWeight: 400,
    },
    menuVoices: {
      color: colorPalette.white,
      fontSize: '1rem',
      padding: '2rem',
      fontWeight: 400,
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 700,
      marginBottom: '2rem',
    },
    text: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    userInfo: {
      flex: 1,
      alignItems: 'flex-end',
      gap: '1rem',
    },
  })
)

interface AsideProps {
  titleLabel?: React.ReactNode
  textLabel?: React.ReactNode
  userMustConfirmBack?: boolean
}

export const Aside: React.FC<AsideProps> = ({
  titleLabel,
  textLabel,
  userMustConfirmBack,
}) => {
  const styles = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [backConfirmationIsOpen, setBackConfirmationIsOpen] = useState(false)

  const handleBack = () => {
    if (userMustConfirmBack) {
      setBackConfirmationIsOpen(true)
    } else {
      goBack()
    }
  }

  const goBack = () => {
    dispatch(resetApp())
    history.push(`${AppRoutes.intro()}`)
  }

  const onCloseBackConfirmation = () => {
    setBackConfirmationIsOpen(false)
  }

  return (
    <>
      <Box
        p="2rem"
        display="flex"
        flexDirection="column"
        className={styles.aside}
      >
        <Button className={styles.btnHome} onClick={handleBack}>
          <ArrowBackIos />
          <Typography>back to home screen</Typography>
        </Button>
        {(history.location.pathname.includes(AppRoutes.groupList()) ||
          history.location.pathname.includes(AppRoutes.userList())) && (
          <>
            <Button
              className={styles.menuVoices}
              onClick={() => history.push(`${AppRoutes.groupList()}`)}
            >
              <Typography>Group List</Typography>
              <ArrowForwardIos />
            </Button>
            <Button
              className={styles.menuVoices}
              onClick={() => history.push(`${AppRoutes.userList()}`)}
            >
              <Typography>User List</Typography>
              <ArrowForwardIos />
            </Button>
          </>
        )}
        {titleLabel || (
          <Typography variant="h2" className={styles.title}>
            CREATE MODE
          </Typography>
        )}
        {textLabel || (
          <Typography className={styles.text}>
            Create a new Virtual Mirror experience and the related QR code link
          </Typography>
        )}

        <UserInfo style={styles.userInfo} />
      </Box>
      {userMustConfirmBack && (
        <Popup
          isOpen={backConfirmationIsOpen}
          onClose={onCloseBackConfirmation}
          onConfirm={goBack}
          title="Are you sure you want to leave?"
          message="By going back to the home screen, you will lose your experience."
        />
      )}
    </>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VmmvModes } from '../../types/vmdata'

const initialState: VmmvModes = {
  isTryOnEnabled: true,
  isPictureModeEnabled: false,
  isVideoModeEnabled: false,
}

const vmmvModes = createSlice({
  name: 'vm-bo/vmmvModes',
  initialState,
  reducers: {
    setVmmvModes: (_, action: PayloadAction<VmmvModes>) => {
      return action.payload
    },
  },
})

export const { setVmmvModes } = vmmvModes.actions
export default vmmvModes.reducer

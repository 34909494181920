import React, { useEffect, useMemo } from 'react'
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core'
import { TitleEdit } from '../components/core/TitleEdit'

// import CheckCircleIcon from '@mui/icons-material/CheckCircle'
// import CircleOutlined from '@mui/icons-material/CircleOutlined'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { WizardStepperFooter } from '../components/WizardStepperFooter'
import { useDispatch, useSelector } from 'react-redux'
import { setVmmvModes } from '../features/visualizationModes/visualizationModesSlice'

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    outer: {
      flexGrow: 1,
      // textAlign: 'center',
    },
    inner: {
      display: 'inline-block',
    },
    title: {
      textAlign: 'left',
      marginBottom: 0,
    },
    options: {
      display: 'flex',
      gap: 30,
      marginBottom: 50,
    },
    modeWrapper: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 250,
      '& .MuiBox-root:first-child': {
        textAlign: 'center',
      },
      '& .MuiBox-root:nth-child(2)': {
        padding: 12,
        border: `1px solid ${palette.primary.main}`,
        flex: 1,
      },
    },
  })
)

const ModeSelectionStyled = styled(Checkbox)(() => ({
  '& .MuiSvgIcon-root': { fontSize: 30 },
}))

const TableCellItem = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  '&.title, &.rowTitle': {
    fontWeight: 'bold',
  },
  '&.MuiTableCell-head': {
    fontSize: '1.35rem',
    backgroundColor: theme.palette.grey[200],
  },
}))

export const VisualizationPage: React.FC = () => {
  const dispatch = useDispatch()
  const styles = useStyles()

  const modes = useSelector((s) => s.vmData.vmmvModes)

  const modeOptions = useMemo(
    () => [
      {
        mode: 'Live Try-On',
        description:
          "The Live Try On mode allows customers to try on their glasses in real time, The model immediately appears on the customer's face",
        isChecked: modes.isTryOnEnabled,
        onChange: () =>
          dispatch(
            setVmmvModes({ ...modes, isTryOnEnabled: !modes.isTryOnEnabled })
          ),
      },
      {
        mode: 'Picture Mode',
        description:
          "Possibility to take a new picture or to upload one. For both options customers don't need to hold the phone just in front of them while trying on glasses.",
        isChecked: modes.isPictureModeEnabled,
        onChange: () =>
          dispatch(
            setVmmvModes({
              ...modes,
              isPictureModeEnabled: !modes.isPictureModeEnabled,
            })
          ),
      },
      {
        mode: 'Video Mode',
        description:
          'The Video mode consists of recording a short 180° video to capture the entire head. After the recording, the fitting of the glasses can be viewed on the pre-recorded video.',
        isChecked: modes.isVideoModeEnabled,
        onChange: () =>
          dispatch(
            setVmmvModes({
              ...modes,
              isVideoModeEnabled: !modes.isVideoModeEnabled,
            })
          ),
      },
    ],
    [dispatch, modes]
  )

  const status = useMemo(() => {
    const hasSelected = modeOptions.some((i) => i.isChecked)
    const allSelected = modeOptions.every((i) => i.isChecked)

    if (allSelected) return 2
    else if (hasSelected) return 1

    return 0
  }, [modeOptions])

  const handleCheck = () => {
    let isEnabledAll = false

    if (status === 0 || status === 1) {
      isEnabledAll = true
    }

    dispatch(
      setVmmvModes({
        isTryOnEnabled: isEnabledAll,
        isPictureModeEnabled: isEnabledAll,
        isVideoModeEnabled: isEnabledAll,
      })
    )
  }

  useEffect(() => {
    dispatch(setVmmvModes(modes))
  }, [dispatch, modes])

  return (
    <>
      <Box className={styles.outer}>
        <Box className={styles.inner}>
          <TitleEdit
            className={styles.title}
            title="Select the visualization mode to be displayed in the experience."
          />
          <Typography
            style={{ marginBottom: 50, fontSize: 14, textAlign: 'left' }}
          >
            Please, be aware that at least one mode is mandatory: you can select
            one, two or all three. <br /> Please note that the mode selected
            applies to all products in the experience.
          </Typography>
          {/* <Box className={styles.options}>
            {modeOptions.map((m) => (
              <Box key={`${m.mode}`} className={styles.modeWrapper}>
                <Box>
                  <ModeSelectionStyled
                    color="primary"
                    icon={<CircleOutlined />}
                    checkedIcon={<CheckCircleIcon />}
                    checked={m.isChecked}
                    onChange={m.onChange}
                  />
                  <Typography style={{ fontSize: 14, display: 'inline' }}>
                    {m.mode}
                  </Typography>
                </Box>
                <Box>{m.description}</Box>
              </Box>
            ))}
          </Box> */}
          <TableContainer
            component={Paper}
            elevation={3}
            style={{ maxWidth: 1024, marginBottom: 50 }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellItem>
                    <ModeSelectionStyled
                      color="primary"
                      checked={status !== 0}
                      checkedIcon={
                        status === 1 ? (
                          <IndeterminateCheckBoxIcon />
                        ) : (
                          <CheckBoxIcon />
                        )
                      }
                      onChange={handleCheck}
                    />
                  </TableCellItem>
                  <TableCellItem className="title">Mode</TableCellItem>
                  <TableCellItem className="title">Description</TableCellItem>
                </TableRow>
              </TableHead>
              <TableBody>
                {modeOptions.map((row) => (
                  <TableRow key={row.mode}>
                    <TableCellItem>
                      <ModeSelectionStyled
                        color="primary"
                        checked={row.isChecked}
                        onChange={row.onChange}
                      />
                    </TableCellItem>
                    <TableCellItem
                      className="rowTitle"
                      style={{ minWidth: 120 }}
                    >
                      {row.mode}
                    </TableCellItem>
                    <TableCellItem>{row.description}</TableCellItem>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <WizardStepperFooter isValid={status !== 0} />
        </Box>
      </Box>
    </>
  )
}

import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '2rem',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: '1.75rem',
    },
  })
)

export const TitleEdit: React.FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => {
  const styles = useStyles()

  return (
    <Typography className={`${styles.title} ${className || ''}`}>
      {title}
    </Typography>
  )
}

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { colorPalette } from '../styleVariables'
import { shallowEqual, useSelector } from 'react-redux'
import { Group } from './ListingGroups'
import GroupServices from '../services/groupServices'
import { GroupsTablePopup } from './core/GroupsTablePopup'
import { GroupsTableBody } from './core/GroupsTableBody'

interface Column {
  id: 'icon' | 'group' | 'actions'
  label: string
}

const columns: Column[] = [
  {
    id: 'icon',
    label: '',
  },
  { id: 'group', label: 'Group' },
  { id: 'actions', label: 'Actions' },
]

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '70vh',
    minHeight: '70vh',
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    overflow: 'auto',
    position: 'relative',
  },
  rowHead: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
  },
  columnHeadCell: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  emptyMessageWrapper: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  exmptyMessage: {
    fontSize: '2rem',
    color: colorPalette.greyDark,
  },
  loaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
  content: {
    padding: '3rem',
    position: 'relative',
    minWidth: '40vw',
  },
  messageWrapper: {
    margin: '3rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  message: {
    fontSize: '1.35rem',
    color: colorPalette.greyLight,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonText: {
    fontSize: '1.35rem',
    color: colorPalette.blue,
  },
  buttonTextRed: {
    fontSize: '1.35rem',
    color: 'red',
  },
})

const EmptyMessage: React.FC = () => {
  const styles = useStyles()

  return (
    <Box className={styles.emptyMessageWrapper}>
      <Typography className={styles.exmptyMessage}>
        Your list is empty!
      </Typography>
    </Box>
  )
}

interface ListingTableProps {
  createStatus: boolean
  setCreateStatus: (s: boolean) => void
  editableStatus: boolean
  setEditableStatus: (s: boolean) => void
  groups: Group[] | undefined
  searchValue: string
  refetchGroup: () => Promise<void>
}

export const ListingGroupsTable: React.FC<ListingTableProps> = ({
  createStatus,
  setCreateStatus,
  editableStatus,
  setEditableStatus,
  groups,
  searchValue,
  refetchGroup,
}) => {
  const styles = useStyles()
  const [loading, setLoading] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [deletedStatus, setDeletedStatus] = useState(false)
  const [isEdited, setisEdited] = useState(false)
  const [isAdded, setisAdded] = useState(false)
  const [rowToDelete, setRowToDelete] = useState<Group | null>(null)
  const [rowToEdit, setRowToEdit] = useState<Group>({
    id: '',
    name: '',
  })
  const [rowToAdd, setRowToAdd] = useState<Group>({
    id: '',
    name: '',
  })

  const onOpenPopup = (row: Group) => {
    setRowToDelete(row)
    setIsOpenPopup(true)
  }

  const onClosePopup = () => {
    setIsOpenPopup(false)
  }

  const handleDelete = async () => {
    if (rowToDelete !== null) {
      setLoading(true)
      await GroupServices.deleteGroup(rowToDelete.id)
      onClosePopup()
      await refetchGroup()
      setLoading(false)
      setDeletedStatus(true)
    }
  }

  const handleEdit = async (row: Group) => {
    if (rowToEdit && rowToEdit.name !== '') {
      setEditableStatus(false)
      setisEdited(true)
      rowToEdit.createdBy = undefined
      setLoading(true)
      const newGroup = await GroupServices.updateGroup(rowToEdit.id, rowToEdit)
      await refetchGroup()
      setLoading(false)
    }
  }

  const handleAddGroup = async () => {
    if (rowToAdd && rowToAdd.name !== '') {
      rowToAdd.createdBy = undefined
      setLoading(true)
      const newGroup = await GroupServices.insertGroup(rowToAdd.name)
      await refetchGroup()
      setLoading(false)
      setisAdded(true)
      setCreateStatus(false)
    }
  }

  const handleAddGroupName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    const value = e.target.value
    setRowToAdd({ ...rowToAdd, [field]: value || '' })
  }

  const handleChangeGroupName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    const value = e.target.value
    setRowToEdit({ ...rowToEdit, [field]: value || '' })
  }
  return (
    <>
      <Paper className={styles.root}>
        <div className={styles.container}>
          <Table stickyHeader>
            <TableHead className={styles.rowHead}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell className={styles.columnHeadCell} key={column.id}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <GroupsTableBody
              setEditableStatus={setEditableStatus}
              setRowToEdit={setRowToEdit}
              setCreateStatus={setCreateStatus}
              rowToAdd={rowToAdd}
              rowToEdit={rowToEdit}
              createStatus={createStatus}
              editableStatus={editableStatus}
              handleAddGroup={handleAddGroup}
              handleEdit={handleEdit}
              onOpenPopup={onOpenPopup}
              handleAddGroupName={handleAddGroupName}
              handleChangeGroupName={handleChangeGroupName}
              searchValue={searchValue}
              groups={groups}
            />
          </Table>
          {loading && (
            <Box className={styles.loaderWrapper}>
              <CircularProgress />
            </Box>
          )}
          {!groups?.length && <EmptyMessage />}
        </div>
      </Paper>
      <Dialog open={isOpenPopup} onClose={onClosePopup} maxWidth="lg">
        <Box className={styles.content}>
          <Box className={styles.messageWrapper}>
            <Typography className={styles.message}>
              Are you sure you want to delete
              <span className={styles.buttonText}> {rowToDelete?.name}</span>?
            </Typography>
          </Box>
          <Box className={styles.buttonsWrapper}>
            <Button onClick={onClosePopup}>
              <Typography className={styles.buttonText}>cancel</Typography>
            </Button>
            <Button onClick={handleDelete}>
              <Typography className={styles.buttonTextRed}>
                delete group
              </Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
      <GroupsTablePopup
        deletedStatus={deletedStatus}
        isEdited={isEdited}
        isAdded={isAdded}
        rowToAdd={rowToAdd}
        rowToDelete={rowToDelete}
        setCreateStatus={setCreateStatus}
        setEditableStatus={setEditableStatus}
        setDeletedStatus={setDeletedStatus}
        setRowToDelete={setRowToDelete}
        setRowToEdit={setRowToEdit}
        setRowToAdd={setRowToAdd}
        setisEdited={setisEdited}
        setisAdded={setisAdded}
        rowToEdit={rowToEdit}
      />
    </>
  )
}

import React, { FC } from 'react'
import {
  Box,
  Button,
  createStyles,
  Dialog,
  makeStyles,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: '3rem',
      position: 'relative',
      minWidth: '40vw',
    },
    title: {
      fontSize: '2.35rem',
      fontWeight: 700,
    },
    messageWrapper: {
      margin: '3rem 0',
      display: 'flex',
      justifyContent: 'center',
    },
    message: {
      fontSize: '1.35rem',
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonText: {
      fontSize: '1.35rem',
    },
  })
)

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  message: string
}

export const Popup: FC<Props> = ({
  isOpen,
  onClose,
  title,
  message,
  onConfirm,
}) => {
  const styles = useStyles()

  const onClick = () => {
    onConfirm()
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
      <Box className={styles.content}>
        <Typography className={styles.title}>{title}</Typography>
        <Box className={styles.messageWrapper}>
          <Typography className={styles.message}>{message}</Typography>
        </Box>
        <Box className={styles.buttonsWrapper}>
          <Button onClick={onClose} style={{ marginRight: '2rem' }}>
            <Typography className={styles.buttonText}>cancel</Typography>
          </Button>
          <Button color="primary" variant="contained" onClick={onClick}>
            <Typography className={styles.buttonText}>Confirm</Typography>
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from './theme'
import { makeStyles, CssBaseline } from '@material-ui/core'
import { Routes } from './routers'
import store from './store'
import { ErrorBoundary } from '../components/ErrorBoundary'

const useStyles = makeStyles({
  '@global': {
    'html, body, #root': {
      height: '100%',
      width: '100%',
      fontSize: '12px',
      overflow: 'hidden',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
    },

    '@keyframes filter-animation': {
      '0%': {
        filter: 'hue-rotate(335deg)',
      },

      '50%': {
        filter: 'hue-rotate(360deg)',
      },

      '100%': {
        filter: 'hue-rotate(335deg)',
      },
    },

    '@keyframes target-fade': {
      '0%': {
        backgroundColor: 'rgba(17, 138, 178, 0.8)',
      },
      '100%': {
        backgroundColor: 'rgba(17, 138, 178, 0)',
      },
    },
  },
})

const App: React.FC = () => {
  useStyles()

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <CssBaseline />
          <Routes />
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  )
}

export default App

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = ''

const createdBy = createSlice({
  name: 'vm-bo/createdBy',
  initialState,
  reducers: {
    setExperienceCreatedBy: (_, action: PayloadAction<string | null>) => {
      return action.payload || ''
    },
  },
})

export const { setExperienceCreatedBy } = createdBy.actions
export default createdBy.reducer

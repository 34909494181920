import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { Add } from '@material-ui/icons'
import { AddProductModal } from '../components/AddProductModal'
import { Product } from '../types/vmdata'
import { ProductsTable } from '../components/ProductsTable'
import { QRCodeModal } from '../components/QRCodeModal'
import { TitleEdit } from '../components/core/TitleEdit'
import { WizardStepperFooter } from '../components/WizardStepperFooter'
import { removeProduct } from '../features/products/productSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      flexGrow: 1,
    },
    title: {
      marginBottom: 0,
      textAlign: 'left',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
    },
    btn: {
      padding: '0.5rem 2rem',
      borderRadius: '3rem',
    },
    btnQr: {
      padding: '0.5rem 2rem',
      borderRadius: '3rem',
      marginRight: '1rem',
    },
    btnText: {
      fontSize: '1.35rem',
      fontWeight: 500,
    },
    tableWrapper: {
      marginBottom: '2rem',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '1rem',
    },
    loaderWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 100,
    },
  })
)

interface Props {
  isNewProduct?: boolean
}

export const EditProductsPage: React.FC<Props> = ({ isNewProduct }) => {
  const styles = useStyles()
  const [isOpen, toggleOpen] = useState(false)
  const [isOpenQr, toggleOpenQr] = useState(false)
  const [editProduct, setEditProduct] = useState<Product | null>(null)
  const dispatch = useDispatch()
  const products = useSelector((state) => state.vmData.products)

  const handleToggle = () => {
    toggleOpen((c) => !c)
  }
  const handleQrToggle = () => {
    toggleOpenQr((c) => !c)
  }

  const onRemove = (product: Product) => {
    dispatch(removeProduct(product))
  }

  const onEdit = (product: Product) => {
    setEditProduct(product)
    handleToggle()
  }

  useEffect(() => {
    if (!isOpen && editProduct) {
      setEditProduct(null)
    }
  }, [isOpen, editProduct])

  const isStepValid = !!products.length

  return (
    <Box className={styles.content}>
      <Box className={styles.header}>
        <Box className={styles.infoContainer}>
          <TitleEdit className={styles.title} title="Product list" />
          <Typography>
            Please note that you should not add more than 15 UPCs.
          </Typography>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleQrToggle}
            className={styles.btnQr}
            disabled={!isStepValid}
          >
            <Typography className={styles.btnText}>try qr code</Typography>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleToggle}
            className={styles.btn}
          >
            <Add />
            <Typography className={styles.btnText}>add product</Typography>
          </Button>
        </Box>
      </Box>
      <Box className={styles.tableWrapper}>
        <ProductsTable onRemove={onRemove} onEdit={onEdit} />
      </Box>
      <AddProductModal
        isOpen={isOpen}
        onClose={handleToggle}
        product={editProduct}
      />
      <QRCodeModal isOpen={isOpenQr} onClose={handleQrToggle} />
      <WizardStepperFooter isValid={isStepValid} isNewProduct={isNewProduct} />
    </Box>
  )
}

import queryString from 'query-string'
import { Users } from '../components/UserList'

export interface User {
  token: string
}

export class UserError extends Error {
  code: string | undefined
  description: string | undefined

  constructor(message: string, code?: string, description?: string) {
    super(message)
    this.code = code
    this.description = description
  }
}

export default class UserServices {
  static apiBaseURL(): string {
    const apiURL = process.env.REACT_APP_VM_API_URL

    if (!apiURL) {
      throw new UserError(
        'REACT_APP_VM_API_URL is not defined in env variables'
      )
    }

    return apiURL
  }

  static async getJwtToken(code: string): Promise<string> {
    const apiURL = UserServices.apiBaseURL()
    const redirectURI = process.env.REACT_APP_IDM_REDIRECT_URI

    if (!redirectURI) {
      throw new UserError(
        'REACT_APP_IDM_REDIRECT_URI is not defined in env variables'
      )
    }

    const response = await fetch(
      queryString.stringifyUrl({
        url: `${apiURL}/login`,
        query: {
          code,
          redirect_uri: redirectURI,
        },
      }),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    )

    if (!response.ok) {
      const body = await response.text()
      console.log(body)
      throw new UserError(
        `UserServices: Failed during JSON parsing ${response.statusText}`,
        response.status.toString(),
        body
      )
    }

    try {
      return await response.json()
    } catch {
      const body = await response.text()
      console.log(body)
      throw new UserError(
        'UserServices: Failed during JSON parsing',
        response.status.toString(),
        body
      )
    }
  }

  static async userLogout(): Promise<string | void> {
    const idmURL = process.env.REACT_APP_IDM_URL
    const apiURL = process.env.REACT_APP_VM_API_URL
    const userToken = localStorage.getItem('userToken')

    localStorage.removeItem('userToken')

    const response = await fetch(`${apiURL}/logout`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      method: 'GET',
    })

    if (!response.ok) {
      const body = await response.text()

      throw new UserError(
        `UserServices: Failed during JSON parsing ${response.statusText}`,
        response.status.toString(),
        body
      )
    }
    window.location.assign(`${idmURL}/app/logout`)
  }

  static async user() {
    const apiURL = process.env.REACT_APP_VM_API_URL
    const userToken = localStorage.getItem('userToken')

    const response = await fetch(`${apiURL}/users/me`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      method: 'GET',
    })

    if (!response.ok) {
      const body = await response.text()

      throw new UserError(
        `UserServices: Failed during JSON parsing ${response.statusText}`,
        response.status.toString(),
        body
      )
    }
    return response.json()
  }

  static async getAllUsers() {
    const apiURL = process.env.REACT_APP_VM_API_URL
    const userToken = localStorage.getItem('userToken')

    const response = await fetch(`${apiURL}/users`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      method: 'GET',
    })

    if (!response.ok) {
      const body = await response.text()

      throw new UserError(
        `UserServices: Failed during JSON parsing ${response.statusText}`,
        response.status.toString(),
        body
      )
    }
    return response.json()
  }

  static async insertUser(user: Omit<Users, 'id'>) {
    const apiURL = process.env.REACT_APP_VM_API_URL
    const userToken = localStorage.getItem('userToken')

    const response = await fetch(`${apiURL}/users`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ ...user, groupId: parseInt(user.groupId || '') }),
    })

    if (!response.ok) {
      const body = await response.text()

      throw new UserError(
        `UserServices: Failed during JSON parsing ${response.statusText}`,
        response.status.toString(),
        body
      )
    }
    return response.json()
  }

  static async updateUser(id: string, user: Users) {
    const apiURL = process.env.REACT_APP_VM_API_URL
    const userToken = localStorage.getItem('userToken')

    const response = await fetch(`${apiURL}/users/${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({ ...user, groupId: parseInt(user.groupId || '') }),
    })

    if (!response.ok) {
      const body = await response.text()

      throw new UserError(
        `UserServices: Failed during JSON parsing ${response.statusText}`,
        response.status.toString(),
        body
      )
    }
    return response.json()
  }

  static async deleteUser(id: string) {
    const apiURL = process.env.REACT_APP_VM_API_URL
    const userToken = localStorage.getItem('userToken')

    const response = await fetch(`${apiURL}/users/${id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
      method: 'DELETE',
    })

    if (!response.ok) {
      const body = await response.text()

      throw new UserError(
        `UserServices: Failed during JSON parsing ${response.statusText}`,
        response.status.toString(),
        body
      )
    }
    return response.json()
  }
}

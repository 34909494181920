import React from 'react'

const InfoIcon: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 199.943 199.943"
      fill="#2A2F58"
      width="32px"
      height="32px"
      className={className}
    >
      <g fill="#010002">
        <path d="M99.972.004C44.85.004 0 44.847 0 99.968c0 55.125 44.847 99.972 99.972 99.972 55.125 0 99.972-44.847 99.972-99.972C199.943 44.847 155.093.004 99.972.004zm0 184.953c-50.168 0-84.996-34.813-84.996-84.989 0-50.172 34.828-84.992 84.996-84.992 50.175 0 85.003 34.817 85.003 84.992 0 50.175-34.828 84.989-85.003 84.989z" />
        <path d="M99.324 67.354c-3.708 0-8.725 3.01-8.725 6.728v75.979c0 3.722 5.017 6.739 8.725 6.739 3.722 0 8.739-3.017 8.739-6.739V74.082c0-3.718-5.021-6.728-8.739-6.728z" />
        <circle r="8.178" cy="48.697" cx="99.746" />
      </g>
    </svg>
  )
}

export default InfoIcon

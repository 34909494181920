import React, { Component } from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import styles from './ErrorBoundary.module.css'

interface ErrorBoundaryStateProps {
  error: {
    message: string
  }
}

export class ErrorBoundary extends Component<any, ErrorBoundaryStateProps> {
  state = {
    error: {
      message: '',
    },
  }

  static getDerivedStateFromError(error: { message: string }) {
    console.log({ error })
    return {
      error: {
        message: error.message || 'Generic error',
      },
    }
  }

  render() {
    const { error } = this.state

    if (error && error.message) {
      return (
        <Container className={styles.container} disableGutters>
          <Box display="flex" flexDirection="column" alignItems={'center'}>
            <Typography className={styles.errorText}>Error</Typography>
            <Typography className={styles.text}>{error.message}</Typography>
          </Box>
        </Container>
      )
    }

    return this.props.children
  }
}
